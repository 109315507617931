export const USER_TYPES = {
  VIEW_ONLY_USER: 0,
  STANDARD_USER: 1,
  POWER_USER: 2,
};

export const USER_TYPE_CHOICES = [
  { value: USER_TYPES.VIEW_ONLY_USER, label: "View-Only User" },
  { value: USER_TYPES.STANDARD_USER, label: "Standard User" },
  { value: USER_TYPES.POWER_USER, label: "Power User" },
];
