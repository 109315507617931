<template>
  <v-list-item id="departmentPermissions" class="px-0">
    <v-list-item-content class="pb-0">
      <section class="d-flex align-center justify-space-between">
        <div>Departments</div>

        <div class="d-flex gap">
          <v-chip
            :color="userHasAccessToAllDepartments ? 'primary' : undefined"
            @click="enableAllUserDepartmentAccess()"
            :disabled="!canManageUsers"
          >
            All
          </v-chip>
          <v-chip
            :color="userHasAccessToSomeDepartments ? 'primary' : undefined"
            @click="showDepartmentAccessDialog = true"
            :disabled="!canManageUsers"
          >
            Selected
          </v-chip>
          <v-chip
            :color="userHasAccessToNoDepartments ? 'primary' : undefined"
            @click="disableAllUserDepartmentAccess()"
            :disabled="!canManageUsers"
          >
            None
          </v-chip>
        </div>
      </section>

      <DepartmentAccessDialog
        v-if="showDepartmentAccessDialog"
        :showDepartmentAccessDialog="showDepartmentAccessDialog"
        :selectedUser="selectedUser"
        @department-dialog-close="showDepartmentAccessDialog = false"
        @saved="onDepartmentAccessSaved"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import contentHeightMixin from "@/mixins/contentHeightMixin";
import DepartmentAccessDialog from "@/components/organization/users/edit-user-dialog/department-form/DepartmentAccessDialog";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DepartmentForm",
  props: {
    selectedUser: Object,
  },
  mixins: [contentHeightMixin, permissionsMixin],
  components: { DepartmentAccessDialog },
  data() {
    return {
      departmentsUserIsIn: [],
      departments: [],
      showDepartmentAccessDialog: false,
    };
  },
  computed: {
    userHasAccessToAllDepartments() {
      return this.departmentsUserIsIn.length === this.departments.length;
    },
    userHasAccessToSomeDepartments() {
      return (
        this.departmentsUserIsIn.length > 0 &&
        this.departmentsUserIsIn.length < this.departments.length
      );
    },
    userHasAccessToNoDepartments() {
      return this.departmentsUserIsIn.length === 0;
    },
  },
  methods: {
    async getDepartmentsUserAreIn(userId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_departments/user/${userId}`);
      this.departmentsUserIsIn = results;
    },
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async onDepartmentAccessSaved() {
      this.showDepartmentAccessDialog = false;
      await this.getDepartments();
      await this.getDepartmentsUserAreIn(this.selectedUser?.user_id);
    },
    async enableAllUserDepartmentAccess() {
      const userDepartmentsToInsert = this.departments.map((d) => {
        const { department_id: departmentId } = d;
        return {
          user_id: this.selectedUser.user_id,
          department_id: departmentId,
        };
      });
      await this.disableAllUserDepartmentAccess();
      await axios.post(`${APIURL}/user_departments/batch`, {
        user_departments: userDepartmentsToInsert,
      });
      await this.getDepartments();
      await this.getDepartmentsUserAreIn(this.selectedUser.user_id);
    },
    async disableAllUserDepartmentAccess() {
      const userId = this.selectedUser.user_id;
      const userDepartmentsToDelete = this.departments.map((d) => {
        const { department_id: departmentId } = d;
        return {
          user_id: userId,
          department_id: departmentId,
        };
      });
      await axios.delete(`${APIURL}/user_departments/batch`, {
        data: {
          user_departments: userDepartmentsToDelete,
        },
      });
      await this.getDepartments();
      await this.getDepartmentsUserAreIn(this.selectedUser.user_id);
    },
  },
  beforeMount() {
    this.getDepartments();
  },
  watch: {
    "selectedUser.user_id": {
      immediate: true,
      handler(userId) {
        this.getDepartmentsUserAreIn(userId);
      },
    },
  },
};
</script>

<style>
.gap {
  gap: 5px;
}
</style>
