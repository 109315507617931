<template>
  <v-dialog
    v-model="showEditCityworksIntegrationDialog"
    max-width="600px"
    persistent
  >
    <v-card
      v-if="
        auth.organization.access_cityworks_811_integration ||
        auth.organization.access_cityworks_ams_integration
      "
    >
      <validation-observer ref="cityworksForm" v-slot="{ valid }">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>Edit Cityworks Integration</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('cityworks-integration-dialog-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <validation-provider
            v-slot="{ errors, valid }"
            name="API URL"
            :rules="{
              regex:
                /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
              required: true,
            }"
          >
            <v-text-field
              v-model="cityworksIntegrationInfo.api_url"
              hide-details="auto"
              name="apiUrl"
              background-color="white"
              label="API URL"
              :error-messages="errors"
              :success="valid"
              hint="Example: https://myCity.com/cityworks/Services/"
              persistent-hint
              @input="
                $emit(
                  'cityworks-user-group-info-changed',
                  cityworksIntegrationInfo
                )
              "
            >
            </v-text-field>
          </validation-provider>

          <BadRequestErrorDialog
            :showBadRequestErrorDialog="Boolean(badRequestError)"
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-end pa-5">
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="saveCityworksSettings"
          >
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
    <v-card v-else>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Cityworks Integration</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('cityworks-integration-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5">
        <a href="#" @click="showChat()">Contact sales</a> to get licensed for
        this integration.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import authObjectMixin from "@/mixins/authObjectMixin";
import BadRequestErrorDialog from "@/components/shared/BadRequestErrorDialog";
import { mapGetters } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "EditCityworksIntegrationDialog",
  props: {
    showEditCityworksIntegrationDialog: Boolean,
  },
  computed: {
    ...mapGetters(["badRequestError"]),
  },
  data() {
    return {
      cityworksIntegrationInfo: {
        api_url: "",
        cityworks_save_locate_request: false,
      },
      auth: {},
      showSave811TicketsToCityworksSection: true,
    };
  },
  components: {
    BadRequestErrorDialog,
  },
  async beforeMount() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.getCityworksUserGroupInfo();
  },
  mixins: [authObjectMixin],
  methods: {
    async getCityworksUserGroupInfo() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/user_group_info`);
      this.cityworksIntegrationInfo = results;
    },
    async saveCityworksSettings() {
      const success = await this.$refs.cityworksForm.validate();
      if (!success) {
        return;
      }
      const { cityworksIntegrationInfo } = this;
      const newCityworksIntegrationInfo = {
        cityworks_save_locate_request: false,
        ...cityworksIntegrationInfo,
      };
      await axios.put(
        `${APIURL}/cityworks/user_group_info`,
        newCityworksIntegrationInfo
      );
      this.$emit("cityworks-settings-saved");
    },
  },
};
</script>
