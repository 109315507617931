var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-simple-table",
    { attrs: { "fixed-header": "", height: _vm.height } },
    [
      _c("thead", [
        _c("tr", [
          _c(
            "th",
            [
              _c("v-simple-checkbox", {
                attrs: {
                  value: _vm.newUsersInDepartment.length === _vm.users.length,
                },
                on: {
                  input: function ($event) {
                    return _vm.toggleAllUsersInDepartment()
                  },
                },
              }),
            ],
            1
          ),
          _c("th", [_vm._v("Name")]),
        ]),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.users, function (u) {
          return _c("tr", { key: u.user_id }, [
            _c(
              "td",
              [
                _c("v-simple-checkbox", {
                  attrs: { value: _vm.isUserInDepartment(u.user_id) },
                  on: {
                    input: function ($event) {
                      return _vm.toggleUserInDepartment(u.user_id)
                    },
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v(_vm._s(`${u.f_name} ${u.l_name}`))]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }