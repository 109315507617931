import { render, staticRenderFns } from "./PortalConnection.vue?vue&type=template&id=58a19b18&scoped=true&"
import script from "./PortalConnection.vue?vue&type=script&lang=js&"
export * from "./PortalConnection.vue?vue&type=script&lang=js&"
import style0 from "./PortalConnection.vue?vue&type=style&index=0&id=58a19b18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a19b18",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58a19b18')) {
      api.createRecord('58a19b18', component.options)
    } else {
      api.reload('58a19b18', component.options)
    }
    module.hot.accept("./PortalConnection.vue?vue&type=template&id=58a19b18&scoped=true&", function () {
      api.rerender('58a19b18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organization/integrations/shared/gis-connection-dialog/PortalConnection.vue"
export default component.exports