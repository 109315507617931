<template>
  <v-dialog
    v-model="showCityworksIntegrationDialog"
    max-width="600px"
    persistent
  >
    <v-card
      v-if="
        auth.organization.access_cityworks_811_integration ||
        auth.organization.access_cityworks_ams_integration
      "
    >
      <validation-observer ref="cityworksForm" v-slot="{ valid }">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>Cityworks Integration</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('cityworks-integration-dialog-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text style="overflow-y: auto; height: 60vh">
          <section class="d-flex justify-end align-center">
            <v-switch
              label="Enable Integration"
              v-model="cityworksIntegrationInfo.cityworks_save_locate_request"
              hide-details
            >
            </v-switch>
          </section>

          <CityworksAccount
            :cityworksIntegrationInfo="cityworksIntegrationInfo"
            @sign-in-status-changed="updateCityworksToken"
          />
          <ServiceRequest
            v-if="hasValidCityworksToken"
            :cityworksIntegrationInfo="cityworksIntegrationInfo"
            @cityworks-user-group-info-changed="
              cityworksIntegrationInfo = {
                ...cityworksIntegrationInfo,
                ...$event,
              }
            "
            @cityworks-integration-settings-changed="
              cityworksIntegrationSettings = {
                ...cityworksIntegrationSettings,
                ...$event,
              }
            "
          />

          <BadRequestErrorDialog
            :showBadRequestErrorDialog="Boolean(badRequestError)"
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-end pa-5">
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="saveCityworksSettings"
          >
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
    <v-card v-else>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Cityworks Integration</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('cityworks-integration-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5">
        <a href="#" @click="showChat()">Contact sales</a> to get licensed for
        this integration.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CityworksAccount from "@/components/organization/integrations/shared/cityworks-integration-dialog/CityworksAccount";
import ServiceRequest from "@/components/organization/integrations/shared/cityworks-integration-dialog/ServiceRequest";
import axios from "axios";
import authObjectMixin from "@/mixins/authObjectMixin";
import moment from "moment";
import BadRequestErrorDialog from "@/components/shared/BadRequestErrorDialog";
import { mapGetters } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CityworksIntegrationDialog",
  props: {
    showCityworksIntegrationDialog: Boolean,
  },
  computed: {
    ...mapGetters(["badRequestError"]),
  },
  data() {
    return {
      cityworksIntegrationSettings: {
        service_request_domain_id: undefined,
        service_request_domain_desc: "",
        service_request_template_id: undefined,
        service_request_template_desc: "",
        attach_pdf: undefined,
        attach_photos: undefined,
      },
      cityworksIntegrationInfo: {
        api_url: "",
        cityworks_save_locate_request: false,
      },
      auth: {},
      hasValidCityworksToken: false,
    };
  },
  components: {
    CityworksAccount,
    ServiceRequest,
    BadRequestErrorDialog,
  },
  async beforeMount() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.getCityworksUserGroupInfo();
    this.validateCityworksToken();
    this.getCityworksLocateRequestSettings();
  },
  mixins: [authObjectMixin],
  methods: {
    async validateCityworksToken() {
      try {
        const {
          data: {
            results: { service_request_token: serviceRequestToken },
          },
        } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
        this.hasValidCityworksToken = Boolean(serviceRequestToken);
      } catch (error) {
        this.hasValidCityworksToken = false;
      }
    },
    async onCityworksUserGroupInfoChanged() {
      const portalUrl =
        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
          localStorage.getItem("portal-url")
        )
          ? localStorage.getItem("portal-url")
          : "https://www.arcgis.com";
      await axios.post(`${APIURL}/cityworks/authenticate_with_esri`, {
        login_name: localStorage.getItem("esri_user"),
        gis_token: localStorage.getItem("esri_token"),
        gis_token_url: `${portalUrl}/sharing/rest/generateToken`,
      });
      this.showServiceRequestSection = false;
      await this.$nextTick();
      this.showServiceRequestSection = true;
    },
    async getCityworksUserGroupInfo() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/user_group_info`);
      this.cityworksIntegrationInfo = results;
    },
    async saveCityworksSettings() {
      const success = await this.$refs.cityworksForm.validate();
      if (!success) {
        return;
      }
      const { cityworksIntegrationSettings, cityworksIntegrationInfo } = this;
      const newCityworksIntegrationInfo = {
        cityworks_save_locate_request: false,
        ...cityworksIntegrationInfo,
      };
      await axios.put(
        `${APIURL}/cityworks/user_group_info`,
        newCityworksIntegrationInfo
      );
      await axios.patch(
        `${APIURL}/cityworks/locate_request_integration`,
        cityworksIntegrationSettings
      );
      this.$emit("cityworks-settings-saved");
    },
    async getCityworksLocateRequestSettings() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
      this.cityworksIntegrationSettings = results;
    },
    showChat() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const {
        email,
        user_id: userId,
        username,
        f_name: fName,
        l_name: lName,
        user_created: userCreated,
        is_gis_admin: isGisAdmin,
        is_locate_request_user: isLocateRequestUser,
        is_power_user: isPowerUser,
        is_view_only_user: isViewOnlyUser,
        is_sys_admin: isSysAdmin,
        organization,
      } = auth;
      const props = {
        email: email ?? "",
        user_id: userId,
        username,
        name: `${fName} ${lName}`,
        user_created: moment(userCreated * 1000).format("M/D/YY"),
        is_admin: isGisAdmin.toString(),
        is_locate_request_user: isLocateRequestUser.toString(),
        is_power_user: isPowerUser.toString(),
        is_view_only_user: isViewOnlyUser.toString(),
        is_sys_admin: isSysAdmin.toString(),
        company: organization.name,
        organization_gis_connection_type: organization.gis_connection_type,
        domain: window.location.hostname,
      };
      window.Beacon("identify", props);
      window.Beacon("config", {
        mode: "askFirst",
      });
      const subject = "Interested in adding Cityworks Integration";
      const text = `Hi,\n\nI'm interested in adding the Cityworks integration to our organization (${organization.name}). Can you please contact me and let me know how that works?\n\nThanks,\n${fName} ${lName}`;
      window.Beacon("prefill", {
        name: `${fName} ${lName}`,
        email: email ?? undefined,
        subject,
        text,
      });
      window.Beacon("event", {
        type: "page-viewed",
        url: document.location.href,
        title: document.title,
      });
      window.Beacon("suggest");
      window.Beacon("open");
    },
    async updateCityworksToken() {
      await this.updateAuthObject();
      await this.validateCityworksToken();
    },
  },
};
</script>
