<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        What map would you like me to load when {{ savedUser.firstName }} signs
        in for the first time?
      </v-alert>
    </v-expand-transition>

    <div v-if="!mapChoiceSubmitted" class="d-flex justify-end">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
      >
        <v-list-item
          v-for="(c, i) of mapChoices"
          :key="`${c.label}-${i}`"
          :style="{
            'border-bottom':
              i !== mapChoices.length - 1 ? '1px solid primary' : '',
            'background-color': 'white',
          }"
          class="my-0 py-0"
          @click="
            user.mapIdSelected = c.value;
            mapChoiceSubmitted = true;
            saveMapChoice();
          "
        >
          <span :style="{ color: 'primary' }">
            {{ c.label }}
          </span>
        </v-list-item>
      </v-list>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ selectedFieldReporterChoiceLabel }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import axios from "axios";
import sleep from "@/mixins/sleep";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Step9UserFormMap",
  props: {
    savedUser: Object,
  },
  computed: {
    selectedFieldReporterChoiceLabel() {
      return this.mapChoices.find((a) => a.value === this.user.mapIdSelected)
        ?.label;
    },
    mapChoices() {
      return this.maps.map((m) => {
        const { map_id: mapId, name } = m;
        return {
          value: mapId,
          label: name,
        };
      });
    },
  },
  data() {
    return {
      mdiSend,
      user: {
        mapIdSelected: "",
      },
      mapChoiceSubmitted: false,
      maps: [],
    };
  },
  methods: {
    async getLocateRequestMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/maps/withlocateservice`);
      this.maps = results;
    },
    async getAllMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/maps`);
      this.maps = results;
    },
    async saveMapChoice() {
      await sleep(250);
      this.$emit("step-9-finished", this.user);
      this.mapChoiceSubmitted = true;
    },
  },
  async beforeMount() {
    if (this.savedUser.isLocator) {
      await this.getLocateRequestMaps();
    } else {
      await this.getAllMaps();
    }
  },
};
</script>
