<template>
  <v-dialog
    :value="showAppIdDescDialog"
    max-width="400px"
    :retain-focus="false"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title> App ID Description </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('portal-app-id-desc-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        This is the App ID from an Application on ArcGIS Enterprise.
        <a
          href="https://utilisync.helpscoutdocs.com/article/72-configure-arcgis-for-portal-to-allow-users-access-to-utilisync"
        >
          Click here
        </a>
        for detailed help article.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AppIdDescDialog",
  props: {
    showAppIdDescDialog: Boolean,
  },
};
</script>
