<template>
  <v-dialog
    :value="showPortalUrlDescDialog"
    max-width="400px"
    :retain-focus="false"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title> Enterprise URL </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('portal-url-desc-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        This is the URL to your Portal for ArcGIS Enterprise Server.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PortalUrlDescDialog",
  props: {
    showPortalUrlDescDialog: Boolean,
  },
};
</script>
