<template>
  <v-dialog
    v-model="showCityworksSignInDialog"
    max-width="350px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Cityworks Sign-in</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('cityworks-sign-in-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <validation-observer ref="form">
          <form @submit.prevent="submit">
            <v-row class="pt-3">
              <v-col cols="12" class="px-4">
                Sign in with your Cityworks credentials.
              </v-col>

              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Username"
                  rules="required"
                >
                  <v-text-field
                    v-model="cityworksUser.username"
                    prepend-icon="mdi-account"
                    label="Username *"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="username"
                    :disabled="loggingIn || loginSuccess"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Password"
                  rules="required"
                >
                  <v-text-field
                    v-model="cityworksUser.password"
                    prepend-icon="mdi-lock"
                    label="Password *"
                    type="password"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="password"
                    :disabled="loggingIn || loginSuccess"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row v-if="loginFailed">
              <v-col cols="12" class="d-flex justify-center red--text">
                Invalid username/password
              </v-col>
            </v-row>

            <v-row v-if="loginSuccess">
              <v-col cols="12" class="d-flex justify-center primary--text">
                You are now signed in.
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  type="submit"
                  elevation="0"
                  raised
                  width="100%"
                  color="primary"
                  depressed
                  :disabled="loggingIn || loginSuccess"
                >
                  <template v-if="loggingIn"> Authenticating </template>
                  <template v-else>
                    <template v-if="loginSuccess"> Success! </template>
                    <template v-else> Sign In </template>
                  </template>
                </v-btn>
              </v-col>
            </v-row>

            <div class="strike" v-if="hasEsriToken">
              <span>OR</span>
            </div>

            <v-row v-if="hasEsriToken">
              <v-col cols="12">
                <v-btn
                  @click="esriLogin"
                  type="submit"
                  elevation="0"
                  raised
                  width="100%"
                  color="primary"
                  outlined
                  :disabled="loggingIn || loginSuccess"
                >
                  Sign in with ArcGIS
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import sleep from "@/mixins/sleep";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CityworksSignInDialog",
  props: {
    showCityworksSignInDialog: Boolean,
    cityworksIntegrationInfo: Object,
  },
  data() {
    return {
      cityworksUser: {
        username: "",
        password: "",
      },
      loggingIn: false,
      loginFailed: false,
      loginSuccess: false,
      hasEsriToken: Boolean(localStorage.getItem("esri_token")),
    };
  },
  methods: {
    async esriLogin() {
      try {
        this.loggingIn = true;
        const portalUrl =
          /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
            localStorage.getItem("portal-url")
          )
            ? localStorage.getItem("portal-url")
            : "https://www.arcgis.com";
        await axios.post(`${APIURL}/cityworks/authenticate_with_esri`, {
          login_name: localStorage.getItem("esri_user"),
          gis_token: localStorage.getItem("esri_token"),
          gis_token_url: `${portalUrl}/sharing/rest/generateToken`,
          update_service_request_token: true,
        });
        this.loggingIn = false;
        this.loginSuccess = true;
        this.$emit("cityworks-sign-in-dialog-succeed");
      } catch (error) {
        this.loggingIn = false;
      }
    },
    async submit() {
      this.loginFailed = false;
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      try {
        this.loggingIn = true;
        await axios.post(
          `${APIURL}/cityworks/service_request_authenticate`,
          this.cityworksUser
        );
        this.loggingIn = false;
        this.loginSuccess = true;
        await sleep(2000);
        this.$emit("cityworks-sign-in-dialog-succeed");
      } catch (error) {
        this.loginFailed = true;
        this.loggingIn = false;
      }
    },
  },
};
</script>

<style>
.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: black;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}
</style>
