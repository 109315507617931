<template>
  <v-dialog :value="showAddDepartmentDialog" max-width="600px" persistent>
    <validation-observer
      ref="createDepartmentForm"
      v-slot="{ valid: isFormValid }"
    >
      <v-card style="background-color: #f1f2f1" class="py-0">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>Add Department</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('add-departmeent-dialog-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          :style="{
            'background-color': '#f1f2f1',
            'overflow-y': 'auto',
            height: '65vh',
            'min-height': '65vh',
          }"
          class="py-0"
          ref="cardText"
        >
          <form @submit.prevent>
            <section ref="cardInnerText">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Department Name"
                rules="required"
              >
                <v-text-field
                  v-model="name"
                  label="Department Name"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="departmentName"
                  :full-width="false"
                />
              </validation-provider>
            </section>

            <section class="py-2">
              <section ref="tabs">
                <v-tabs
                  v-model="tab"
                  centered
                  background-color="#f1f2f1"
                  class="pb-2"
                >
                  <v-tab color="primary">Users</v-tab>
                  <v-tab color="primary">Maps</v-tab>
                </v-tabs>
              </section>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <DepartmentUsersTable
                        v-model="userDepartments"
                        :height="`${tableHeight}px`"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <MapDepartmentsTable
                        v-model="departmentMaps"
                        :height="`${tableHeight}px`"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </section>
          </form>

          <ConfirmAddAllLayerDepartmentsDialog
            @close="showConfirmAddAllLayerDepartmentsDialog = false"
            @confirm="
              showConfirmAddAllLayerDepartmentsDialog = false;
              createDepartment();
            "
            :showConfirmAddAllLayerDepartmentsDialog="
              showConfirmAddAllLayerDepartmentsDialog
            "
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-end px-5 py-3">
          <v-btn
            color="primary"
            :disabled="!isFormValid"
            @click="tryCreateDepartment"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import axios from "axios";
import DepartmentUsersTable from "@/components/organization/departments/shared/DepartmentUsersTable";
import MapDepartmentsTable from "@/components/organization/departments/shared/MapDepartmentsTable";
import ConfirmAddAllLayerDepartmentsDialog from "@/components/organization/departments/shared/ConfirmAddAllLayerDepartmentsDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddDepartmentDialog",
  props: { showAddDepartmentDialog: Boolean },
  components: {
    DepartmentUsersTable,
    MapDepartmentsTable,
    ConfirmAddAllLayerDepartmentsDialog,
  },
  computed: {
    departmentMapsChanged() {
      const { departmentMaps } = this;
      return departmentMaps.length > 0;
    },
  },
  data() {
    return {
      name: "",
      tab: null,
      userDepartments: [],
      departmentMaps: [],
      showConfirmAddAllLayerDepartmentsDialog: false,
      tableHeight: 0,
    };
  },
  mounted() {
    this.getTableHeight();
  },
  beforeDestroy() {
    this.$options.mutationObserver?.disconnect();
    this.$options.resizeObserver?.disconnect();
  },
  methods: {
    getTableHeight() {
      this.$options.mutationObserver = new MutationObserver(() => {
        this.$options.resizeObserver = new ResizeObserver(() => {
          this.tableHeight =
            this.$refs.cardText?.clientHeight -
            this.$refs.cardInnerText?.clientHeight -
            this.$refs.tabs?.clientHeight -
            60;
        });
        if (this.$refs.cardText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardText);
        }

        if (this.$refs.cardInnerText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardInnerText);
        }

        if (this.$refs.tabs instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.tabs);
        }
      });
      const config = { attributes: true, childList: true, subtree: true };
      this.$options.mutationObserver.observe(document.body, config);
    },
    async tryCreateDepartment() {
      if (this.departmentMapsChanged) {
        this.showConfirmAddAllLayerDepartmentsDialog = true;
        return;
      }
      await this.createDepartment();
    },
    async createDepartment() {
      const success = await this.$refs.createDepartmentForm.validate();
      if (!success) {
        return;
      }
      const { name } = this;
      const auth = JSON.parse(localStorage.getItem("auth"));
      const { user_group_id: userGroupId } = auth;
      const {
        data: { results: department },
      } = await axios.post(`${APIURL}/departments`, {
        user_group_id: userGroupId,
        name,
      });
      const { department_id: departmentId } = department;
      const userDepartments = this.userDepartments.map((du) => {
        return { ...du, department_id: departmentId };
      });
      await axios.post(`${APIURL}/user_departments/batch`, {
        user_departments: userDepartments,
      });
      const departmentMaps = this.departmentMaps.map((dm) => {
        return { ...dm, department_id: departmentId };
      });
      await axios.post(`${APIURL}/department_maps/batch`, {
        department_maps: departmentMaps,
      });
      await Promise.all(
        departmentMaps.map(async (dm) => {
          const { map_id: mapId } = dm;
          const params = {
            map_id: mapId,
            user_group_id: userGroupId,
          };
          const {
            data: { results: mapServices },
          } = await axios.get(`${APIURL}/map_services/inmap`, {
            params,
          });
          const departmentMapServices = mapServices.map((ms) => {
            const { map_service_id: mapServiceId } = ms;
            return {
              map_service_id: mapServiceId,
              department_id: departmentId,
            };
          });
          await axios.post(`${APIURL}/department_map_services/batch`, {
            department_map_services: departmentMapServices,
          });
        })
      );
      this.$emit("saved");
    },
  },
};
</script>
