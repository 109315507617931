<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        Sorry, that username is already taken. Please enter another username.
      </v-alert>
    </v-expand-transition>

    <div v-if="!userNameSubmitted">
      <validation-observer ref="userNameForm">
        <form @submit.prevent="saveUserName">
          <validation-provider>
            <v-text-field
              outlined
              color="#E5E5E5"
              v-model="user.username"
              hide-details="auto"
              name="username"
              single-line
              background-color="white"
              full-width
              ref="usernameInput"
              @input="validateUsername"
            >
              <template v-slot:append>
                <div class="mt-n2">
                  <v-btn
                    icon
                    type="submit"
                    color="primary"
                    :disabled="!user.username"
                  >
                    <v-icon>
                      {{ mdiSend }}
                    </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </form>
      </validation-observer>
    </div>
    <div v-else class="d-flex justify-end">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ user.username }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import axios from "axios";
const APIURL = process.env.VUE_APP_API_URL;
export default {
  name: "RepeatUserNameForm",
  data() {
    return {
      mdiSend,
      user: {
        username: "",
      },
      userNameSubmitted: false,
      usernameValid: false,
    };
  },
  methods: {
    async validateUsername() {
      await this.$nextTick();
      this.usernameValid = await this.$refs.userNameForm.validate();
    },
    async saveUserName() {
      const success = await this.$refs.userNameForm.validate();
      if (!success) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users_by_username/${this.user.username}`);
      if (!results) {
        this.$emit("step-1-finished", this.user);
      } else {
        this.$emit("show-repeat-username-form-again");
      }
      this.userNameSubmitted = true;
    },
  },
  async beforeMoount() {
    await this.$nextTick();
    this.$refs.usernameInput.$refs.input.focus();
  },
};
</script>
