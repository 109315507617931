var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-3",
      staticStyle: { width: "750px", "max-width": "750px" },
    },
    [
      _c(
        "p",
        { staticClass: "caption" },
        [
          _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiAccountMultiple) + " ")]),
          _vm._v(" Users "),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { width: "750px", "max-width": "750px" } },
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 pb-0 pt-2" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "px-3 mt-0 mb-3 d-flex justify-space-between gap align-center",
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c("v-text-field", {
                        staticClass: "pb-2 mb-1",
                        attrs: {
                          label: "Search",
                          "hide-details": "auto",
                          color: "primary",
                          name: "keyword",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c("v-icon", [
                                  _vm._v(" " + _vm._s(_vm.mdiMagnify) + " "),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.keyword,
                          callback: function ($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword",
                        },
                      }),
                      _c(
                        "v-chip",
                        {
                          attrs: { id: "activeInactiveUsersChip" },
                          on: {
                            click: function ($event) {
                              _vm.showActiveUsers = !_vm.showActiveUsers
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showActiveUsers
                                  ? "Active Users"
                                  : "Inactive Users"
                              ) +
                              " "
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-1 mr-n1",
                              attrs: { icon: "", "x-small": "" },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(_vm.mdiSyncCircle) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              color: "primary",
                              id: "addUserBtn",
                              disabled: !_vm.canManageUsers,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showAddUserDialog = true
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { small: "" } },
                              [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]
                            ),
                            _vm._v(" User "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-menu",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              text: "",
                                              color: "primary",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { staticClass: "mr-1" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.mdiPlusCircle) +
                                              " "
                                          ),
                                        ]),
                                        _vm._v(" Fields "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-text",
                                  _vm._l(_vm.nonEmptyHeaders, function (h) {
                                    return _c("v-checkbox", {
                                      key: h.value,
                                      staticClass: "py-0 my-0",
                                      attrs: { label: h.text },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      model: {
                                        value: _vm.headersEnabled[h.value],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.headersEnabled,
                                            h.value,
                                            $$v
                                          )
                                        },
                                        expression: "headersEnabled[h.value]",
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("v-data-table", {
                staticClass: "cursor-pointer",
                attrs: {
                  headers: _vm.filteredHeaders,
                  items: _vm.filteredUsers,
                  "hide-default-footer": "",
                  "disable-pagination": "",
                  "item-key": "user_id",
                  search: _vm.keyword,
                  id: "filteredUsers",
                },
                on: { "click:row": _vm.onRowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.last_map_opened`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getMapName(item.last_map_opened)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.is_gis_admin`,
                      fn: function ({ item }) {
                        return [
                          item.is_gis_admin
                            ? _c("v-icon", { attrs: { color: "#50A859" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiCheck) + " "),
                              ])
                            : _c("v-icon", { attrs: { color: "#FF0000" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiClose) + " "),
                              ]),
                        ]
                      },
                    },
                    {
                      key: `item.is_power_user`,
                      fn: function ({ item }) {
                        return [
                          item.is_power_user
                            ? _c("v-icon", { attrs: { color: "#50A859" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiCheck) + " "),
                              ])
                            : _c("v-icon", { attrs: { color: "#FF0000" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiClose) + " "),
                              ]),
                        ]
                      },
                    },
                    {
                      key: `item.locate_requests_user`,
                      fn: function ({ item }) {
                        return [
                          item.locate_requests_user
                            ? _c("v-icon", { attrs: { color: "#50A859" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiCheck) + " "),
                              ])
                            : _c("v-icon", { attrs: { color: "#FF0000" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiClose) + " "),
                              ]),
                        ]
                      },
                    },
                    {
                      key: `item.user_id`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDotsVertical) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "py-1",
                                      on: {
                                        click: function ($event) {
                                          _vm.showEditUserDialog = true
                                          _vm.selectedUser = item
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action-text",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pr-1" },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mdiAccountEdit
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "overflowText" },
                                            [_vm._v("Edit User")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm.showAddUserDialog
                ? _c("AddUserDialog", {
                    attrs: {
                      showAddUserDialog: _vm.showAddUserDialog,
                      userGroup: _vm.userGroup,
                    },
                    on: {
                      "add-user-dialog-close": function ($event) {
                        _vm.showAddUserDialog = false
                      },
                      "user-created": function ($event) {
                        _vm.showAddUserDialog = false
                        _vm.getUserGroupUsers()
                      },
                    },
                  })
                : _vm._e(),
              _vm.showEditUserDialog
                ? _c("EditUserDialog", {
                    attrs: {
                      showEditUserDialog: _vm.showEditUserDialog,
                      userGroup: _vm.userGroup,
                      selectedUser: _vm.selectedUser,
                    },
                    on: {
                      "edit-user-dialog-close": function ($event) {
                        _vm.showEditUserDialog = false
                      },
                      "user-saved": function ($event) {
                        _vm.showEditUserDialog = false
                        _vm.getUserGroupUsers()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }