var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Cell phone so " +
                  _vm._s(_vm.savedUser.firstName) +
                  " can receive text notifications? By entering a phone number, you are opting-in to receive text messages. You can skip this step. "
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.cellPhoneSubmitted
        ? _c("div", [
            _c(
              "div",
              [
                _c("validation-observer", { ref: "cellPhoneForm" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveCellPhone.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "validation-provider",
                        {
                          attrs: {
                            rules: {
                              regex:
                                /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            },
                          },
                        },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##########",
                                expression: "'##########'",
                              },
                            ],
                            ref: "cellPhone",
                            attrs: {
                              outlined: "",
                              color: "#E5E5E5",
                              "hide-details": "auto",
                              name: "cellPhone",
                              "single-line": "",
                              "background-color": "white",
                              "full-width": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "mt-n2" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                type: "submit",
                                                color: "primary",
                                                disabled: !_vm.user.cellPhone,
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.mdiSend) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3604886059
                            ),
                            model: {
                              value: _vm.user.cellPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "cellPhone", $$v)
                              },
                              expression: "user.cellPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.cellPhoneSubmitted = true
                        _vm.$emit("step-4-finished", _vm.user)
                      },
                    },
                  },
                  [_vm._v(" Skip ")]
                ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "d-flex justify-end my-5" },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    outlined: "",
                    rounded: "",
                    width: "fit-content",
                    dense: "",
                  },
                },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.user.cellPhone || "Skipped") + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }