<template>
  <v-simple-table fixed-header :height="height">
    <thead>
      <tr>
        <th>
          <v-simple-checkbox
            :value="newMapsInDepartment.length === maps.length"
            @input="toggleAllMapsInDepartment()"
          ></v-simple-checkbox>
        </th>
        <th>Name</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="m of maps" :key="m.map_id">
        <td>
          <v-simple-checkbox
            :value="isMapInDepartment(m.map_id)"
            @input="toggleMapInDepartment(m.map_id)"
          ></v-simple-checkbox>
        </td>
        <td>{{ m.name }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DepartmentMapsTable",
  props: {
    value: Array,
    departmentId: String,
    height: String,
  },
  data() {
    return {
      maps: [],
      mapsInDepartment: [],
      newMapsInDepartment: [],
    };
  },
  methods: {
    async getUserGroupMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/maps`);
      this.maps = results;
    },
    isMapInDepartment(mapId) {
      const idsOfMapsInDepartment = this.newMapsInDepartment.map(
        (u) => u.map_id
      );
      return idsOfMapsInDepartment.includes(mapId);
    },
    async toggleMapInDepartment(mapId) {
      const { departmentId } = this;
      if (this.isMapInDepartment(mapId)) {
        const index = this.newMapsInDepartment.findIndex(
          (m) => m.map_id === mapId
        );

        this.newMapsInDepartment.splice(index, 1);
      } else {
        const mapDepartment = {
          department_id: departmentId,
          map_id: mapId,
        };
        this.newMapsInDepartment.push(mapDepartment);
      }
      this.$emit("input", cloneDeep(this.newMapsInDepartment));
    },
    async toggleAllMapsInDepartment() {
      if (this.newMapsInDepartment.length === this.maps.length) {
        this.newMapsInDepartment = [];
      } else {
        this.newMapsInDepartment = this.maps.map((m) => {
          const { departmentId } = this;
          const { map_id: mapId } = m;
          return {
            map_id: mapId,
            department_id: departmentId,
          };
        });
      }
      this.$emit("input", cloneDeep(this.newMapsInDepartment));
    },
  },
  beforeMount() {
    this.getUserGroupMaps();
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.mapsInDepartment = cloneDeep(val);
          this.newMapsInDepartment = cloneDeep(this.mapsInDepartment);
        }
      },
    },
  },
};
</script>
