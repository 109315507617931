<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
        v-show="connectedToEsri"
      >
        I see your organization is connected to ArcGIS Online/Enterprise. If you
        enter a username below, the user will be able to use ArcGIS
        Online/Enterprise to sign in. You can skip this step.
      </v-alert>
    </v-expand-transition>

    <div v-if="connectedToEsri">
      <div v-if="!esriUserNameSubmitted">
        <div>
          <validation-observer ref="esriUserNameForm">
            <form @submit.prevent="saveEsriUserName">
              <validation-provider>
                <v-text-field
                  outlined
                  color="#E5E5E5"
                  v-model="user.esriUsername"
                  hide-details="auto"
                  name="serviceName"
                  single-line
                  background-color="white"
                  full-width
                  ref="esriUsernameInput"
                >
                  <template v-slot:append>
                    <div class="mt-n2">
                      <v-btn
                        icon
                        type="submit"
                        color="primary"
                        :disabled="!user.esriUsername"
                      >
                        <v-icon>
                          {{ mdiSend }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-text-field>
              </validation-provider>
            </form>
          </validation-observer>
        </div>
        <div class="d-flex justify-end my-5">
          <v-btn
            text
            @click="
              esriUserNameSubmitted = true;
              $emit('step-2-finished', user);
            "
            color="primary"
          >
            Skip
          </v-btn>
        </div>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list outlined rounded width="fit-content" dense>
          <v-list-item>
            {{ user.esriUsername || "Skipped" }}
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "Step2UserForm",
  data() {
    return {
      mdiSend,
      user: {
        esriUserName: "",
      },
      esriUserNameSubmitted: false,
    };
  },
  computed: {
    connectedToEsri() {
      const { gis_connection_type: gisConnectionType } = this.userGroup;
      return gisConnectionType === "agol" || gisConnectionType === "portal";
    },
  },
  props: {
    userGroup: Object,
  },
  methods: {
    async saveEsriUserName() {
      const success = await this.$refs.esriUserNameForm.validate();
      if (!success) {
        return;
      }
      await sleep(250);
      this.esriUserNameSubmitted = true;
      this.$emit("step-2-finished", this.user);
    },
  },
  async beforeMount() {
    if (!this.connectedToEsri) {
      this.$emit("step-2-finished", this.user);
      return;
    }
    await this.$nextTick();
    this.$refs.esriUsernameInput.$refs.input.focus();
  },
};
</script>
