<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        What app do you want {{ savedUser.f_name }} to sign into first?
      </v-alert>
    </v-expand-transition>

    <div v-if="!appSubmitted" class="d-flex justify-end">
      <form>
        <v-list
          :style="{
            width: 'fit-content',
            'border-radius': '1%',
            border: '1px solid primary',
          }"
          class="py-0"
          dense
        >
          <v-list-item
            v-for="(c, i) of appForPasswordCreationChoices"
            :key="c.label"
            :style="{
              'border-bottom':
                i !== appForPasswordCreationChoices.length - 1
                  ? '1px solid primary'
                  : '',
              'background-color': 'white',
            }"
            class="my-0 py-0"
            @click="
              selectedApp = c.value;
              selectAppLabel = c.label;
              appSubmitted = true;
              saveApp();
            "
          >
            <span :style="{ color: 'primary' }">
              {{ c.label }}
            </span>
          </v-list-item>
        </v-list>
      </form>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ selectAppLabel }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "Step10UserFormApp",
  props: {
    savedUser: Object,
    appForPasswordCreationChoices: Array,
  },
  data() {
    return {
      mdiSend,
      appSubmitted: false,
      selectedApp: undefined,
      selectAppLabel: undefined,
    };
  },
  methods: {
    async saveApp() {
      await sleep(250);
      this.$emit("step-10-finished", this.selectedApp);
      this.appSubmitted = true;
    },
  },
};
</script>
