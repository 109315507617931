var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "py-0 mt-4" }, [_vm._v("Permissions")]),
      _c(
        "v-card",
        [
          _c("v-card-text", [
            _vm.userGroup.access_811_tms_app
              ? _c(
                  "div",
                  [
                    _c(
                      "section",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center py-0 my-0",
                      },
                      [
                        _c("p", { staticClass: "py-0 mb-2" }, [
                          _vm._v("UtiliSync TMS"),
                        ]),
                      ]
                    ),
                    _c("p", { staticClass: "py-0 my-0" }, [
                      _vm._v("User Can:"),
                    ]),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Assign Tickets",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.assignTickets,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "assignTickets", $$v)
                        },
                        expression: "user.assignTickets",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Be Assigned Tickets",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.isLocator,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "isLocator", $$v)
                        },
                        expression: "user.isLocator",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "View Tickets Assigned to Others",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.viewTicketsAssignedToOthers,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "viewTicketsAssignedToOthers", $$v)
                        },
                        expression: "user.viewTicketsAssignedToOthers",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Receive 811 Notifications",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.receive811Notifications,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "receive811Notifications", $$v)
                        },
                        expression: "user.receive811Notifications",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "View Ticket Dashboard",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.viewDashboard,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "viewDashboard", $$v)
                        },
                        expression: "user.viewDashboard",
                      },
                    }),
                    _c("validation-provider", {
                      attrs: { name: "Reports To" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  staticClass: "pb-4",
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    label: "Reports To",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "reportsTo",
                                    items: _vm.userChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                    disabled: !_vm.canManageUsers,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$emit("input", _vm.user)
                                    },
                                  },
                                  model: {
                                    value: _vm.user.reportsToUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "reportsToUserId", $$v)
                                    },
                                    expression: "user.reportsToUserId",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3910712448
                      ),
                    }),
                    _c("v-divider"),
                    _c("Account811Form", {
                      attrs: { selectedUser: _vm.selectedUser },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.userGroup.access_field_reports_app
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center",
                      },
                      [
                        _c("div", { staticClass: "py-0 mb-2 mt-4" }, [
                          _vm._v("UtiliSync Inspect"),
                        ]),
                      ]
                    ),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Edit Scheduled Forms",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.editSchduledForms,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "editSchduledForms", $$v)
                        },
                        expression: "user.editSchduledForms",
                      },
                    }),
                    _c("validation-provider", {
                      attrs: { name: "User Type", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  class: { "pb-4": _vm.departments.length > 0 },
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    label: "User Type",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "userType",
                                    items: _vm.filteredUserTypeChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                    disabled: !_vm.canManageUsers,
                                  },
                                  on: { change: _vm.onUserTypeChange },
                                  model: {
                                    value: _vm.userTypeChoice,
                                    callback: function ($$v) {
                                      _vm.userTypeChoice = $$v
                                    },
                                    expression: "userTypeChoice",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2074906551
                      ),
                    }),
                    _vm.departments.length > 0 ? _c("v-divider") : _vm._e(),
                    _vm.departments.length > 0
                      ? _c("DepartmentForm", {
                          attrs: { selectedUser: _vm.selectedUser },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.userBeingEdited.isAdmin
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center",
                      },
                      [
                        _c("div", { staticClass: "py-0 mb-2 mt-4" }, [
                          _vm._v("Admin"),
                        ]),
                      ]
                    ),
                    _c("p", { staticClass: "py-0 my-0" }, [
                      _vm._v("User Can:"),
                    ]),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Manage Users",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.manageUsers,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "manageUsers", $$v)
                        },
                        expression: "user.manageUsers",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Manage Maps",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.manageMaps,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "manageMaps", $$v)
                        },
                        expression: "user.manageMaps",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Manage Layers",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.manageLayers,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "manageLayers", $$v)
                        },
                        expression: "user.manageLayers",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Manage Forms",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.manageForms,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "manageForms", $$v)
                        },
                        expression: "user.manageForms",
                      },
                    }),
                    _c("v-switch", {
                      staticClass: "py-0 my-1",
                      attrs: {
                        label: "Manage 811 Codes",
                        "hide-details": "",
                        disabled: !_vm.canManageUsers,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.user)
                        },
                      },
                      model: {
                        value: _vm.user.manage811Codes,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "manage811Codes", $$v)
                        },
                        expression: "user.manage811Codes",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }