var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c("validation-observer", { ref: "portalUrlForm" }, [
            _c(
              "form",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "py-0 my-0",
                        attrs: { cols: "12", id: "enterpriseURL" },
                      },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "Enterprise URL",
                            rules: {
                              required: true,
                              regex:
                                /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center gap" },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v("Enterprise URL"),
                                      ]),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-center",
                                          attrs: {
                                            fab: "",
                                            width: "15px",
                                            height: "15px",
                                            color: "primary",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showPortalUrlDescDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.mdiHelp) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "py-0 my-0",
                                    attrs: {
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "name",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$emit(
                                          "portal-updated",
                                          _vm.portal
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.portal.portalUrl,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.portal,
                                          "portalUrl",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "portal.portalUrl",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "py-0 my-0 mt-3",
                        attrs: { cols: "12", id: "addID" },
                      },
                      [
                        _c("validation-provider", {
                          attrs: { name: "APP ID", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center gap" },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v("APP ID"),
                                      ]),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-center",
                                          attrs: {
                                            fab: "",
                                            width: "15px",
                                            height: "15px",
                                            color: "primary",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showAppIdDescDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.mdiHelp) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "py-0 my-0",
                                    attrs: {
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "name",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$emit(
                                          "portal-updated",
                                          _vm.portal
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.portal.appId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.portal,
                                          "appId",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "portal.appId",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.showPortalUrlDescDialog
            ? _c("PortalUrlDescDialog", {
                attrs: { showPortalUrlDescDialog: _vm.showPortalUrlDescDialog },
                on: {
                  "portal-url-desc-dialog-close": function ($event) {
                    _vm.showPortalUrlDescDialog = false
                  },
                },
              })
            : _vm._e(),
          _vm.showAppIdDescDialog
            ? _c("AppIdDescDialog", {
                attrs: { showAppIdDescDialog: _vm.showAppIdDescDialog },
                on: {
                  "portal-app-id-desc-dialog-close": function ($event) {
                    _vm.showAppIdDescDialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }