<template>
  <v-simple-table fixed-header :height="height">
    <thead>
      <tr>
        <th>
          <v-simple-checkbox
            :value="newUsersInDepartment.length === users.length"
            @input="toggleAllUsersInDepartment()"
          ></v-simple-checkbox>
        </th>
        <th>Name</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="u of users" :key="u.user_id">
        <td>
          <v-simple-checkbox
            :value="isUserInDepartment(u.user_id)"
            @input="toggleUserInDepartment(u.user_id)"
          ></v-simple-checkbox>
        </td>
        <td>{{ `${u.f_name} ${u.l_name}` }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DepartmentUsersTable",
  props: {
    value: Array,
    departmentId: String,
    height: String,
  },
  data() {
    return {
      users: [],
      usersInDepartment: [],
      newUsersInDepartment: [],
    };
  },
  methods: {
    async getUserGroupUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results.filter((u) => u.is_active && !u.is_contact);
    },
    isUserInDepartment(userId) {
      const idsOfUsersInDepartment = this.newUsersInDepartment.map(
        (u) => u.user_id
      );
      return idsOfUsersInDepartment.includes(userId);
    },
    async toggleUserInDepartment(userId) {
      const { departmentId } = this;
      if (this.isUserInDepartment(userId)) {
        const index = this.newUsersInDepartment.findIndex(
          (u) => u.user_id === userId
        );

        this.newUsersInDepartment.splice(index, 1);
      } else {
        const userDepartment = {
          department_id: departmentId,
          user_id: userId,
        };
        this.newUsersInDepartment.push(userDepartment);
      }
      this.$emit("input", cloneDeep(this.newUsersInDepartment));
    },
    async toggleAllUsersInDepartment() {
      if (this.newUsersInDepartment.length === this.users.length) {
        this.newUsersInDepartment = [];
      } else {
        this.newUsersInDepartment = this.users.map((u) => {
          const { departmentId } = this;
          const { user_id: userId } = u;
          return {
            user_id: userId,
            department_id: departmentId,
          };
        });
      }
      this.$emit("input", cloneDeep(this.newUsersInDepartment));
    },
  },
  beforeMount() {
    this.getUserGroupUsers();
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.usersInDepartment = cloneDeep(val);
          this.newUsersInDepartment = cloneDeep(this.usersInDepartment);
        }
      },
    },
  },
};
</script>
