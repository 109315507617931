export default {
  data() {
    return {
      contentHeight: 0,
    };
  },
  methods: {
    onResize() {
      this.contentHeight =
        window.innerHeight -
        (this.$refs.toolbar?.$el?.clientHeight ?? 0) -
        (this.$refs.cardAction?.clientHeight ?? 0);
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
