var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      _c("p", { staticClass: "caption" }, [_vm._v("Service Request")]),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("validation-provider", {
                attrs: { name: "Domain" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors, valid }) {
                      return [
                        _c("v-select", {
                          attrs: {
                            autocomplete: "off",
                            label: "Domain",
                            "hide-details": "auto",
                            "error-messages": errors,
                            success: valid,
                            color: "primary",
                            name: "requestDomain",
                            items: _vm.serviceRequestDomainChoices,
                            "item-text": "label",
                            "item-value": "value",
                          },
                          on: {
                            change: function ($event) {
                              _vm.cityworksIntegrationSettings.service_request_template_id =
                                undefined
                              _vm.getCityworksRequestTemplates()
                              _vm.$emit(
                                "cityworks-integration-settings-changed",
                                _vm.cityworksIntegrationSettings
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.cityworksIntegrationSettings
                                .service_request_domain_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cityworksIntegrationSettings,
                                "service_request_domain_id",
                                $$v
                              )
                            },
                            expression:
                              "cityworksIntegrationSettings.service_request_domain_id",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("validation-provider", {
                attrs: { name: "Template" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors, valid }) {
                      return [
                        _c("v-select", {
                          attrs: {
                            autocomplete: "off",
                            label: "Template",
                            "hide-details": "auto",
                            "error-messages": errors,
                            success: valid,
                            color: "primary",
                            name: "requestTemplate",
                            items: _vm.serviceRequestTemplateChoices,
                            "item-text": "label",
                            "item-value": "value",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$emit(
                                "cityworks-integration-settings-changed",
                                _vm.cityworksIntegrationSettings
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.cityworksIntegrationSettings
                                .service_request_template_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cityworksIntegrationSettings,
                                "service_request_template_id",
                                $$v
                              )
                            },
                            expression:
                              "cityworksIntegrationSettings.service_request_template_id",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("v-switch", {
                attrs: { label: "Attach PDF", "hide-details": "" },
                on: {
                  change: function ($event) {
                    return _vm.$emit(
                      "cityworks-integration-settings-changed",
                      _vm.cityworksIntegrationSettings
                    )
                  },
                },
                model: {
                  value: _vm.cityworksIntegrationSettings.attach_pdf,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.cityworksIntegrationSettings,
                      "attach_pdf",
                      $$v
                    )
                  },
                  expression: "cityworksIntegrationSettings.attach_pdf",
                },
              }),
              _c("v-switch", {
                attrs: { label: "Attach Photos", "hide-details": "" },
                on: {
                  change: function ($event) {
                    return _vm.$emit(
                      "cityworks-integration-settings-changed",
                      _vm.cityworksIntegrationSettings
                    )
                  },
                },
                model: {
                  value: _vm.cityworksIntegrationSettings.attach_photos,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.cityworksIntegrationSettings,
                      "attach_photos",
                      $$v
                    )
                  },
                  expression: "cityworksIntegrationSettings.attach_photos",
                },
              }),
              _c("div", [
                _vm._v(
                  " To add Labor to the service request, please configure it on the form used to close the ticket. "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }