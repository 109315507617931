<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        Timezone?
      </v-alert>
    </v-expand-transition>

    <div v-if="!timeZoneSubmitted" class="d-flex justify-end">
      <form>
        <v-list
          :style="{
            width: 'fit-content',
            'border-radius': '1%',
            border: '1px solid primary',
          }"
          class="py-0"
          dense
        >
          <v-list-item
            v-for="(c, i) of TIME_ZONE_CHOICES"
            :key="c.label"
            :style="{
              'border-bottom':
                i !== TIME_ZONE_CHOICES.length - 1 ? '1px solid primary' : '',
              'background-color': 'white',
            }"
            class="my-0 py-0"
            @click="
              user.timeZone = c.label;
              timeZoneSubmitted = true;
              saveTimeZone();
            "
          >
            <span :style="{ color: 'primary' }">
              {{ c.label }}
            </span>
          </v-list-item>
        </v-list>
      </form>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ user.timeZone }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

const TIME_ZONE_CHOICES = [
  { value: "US/Pacific", label: "US/Pacific" },
  { value: "US/Mountain", label: "US/Mountain" },
  { value: "US/Central", label: "US/Central" },
  { value: "US/Eastern", label: "US/Eastern" },
];

export default {
  name: "Step5UserFormTimeZone",
  data() {
    return {
      mdiSend,
      user: {
        timeZone: "",
      },
      timeZoneSubmitted: false,
      TIME_ZONE_CHOICES,
    };
  },
  methods: {
    async saveTimeZone() {
      await sleep(250);
      this.$emit("step-5-finished", this.user);
      this.timeZoneSubmitted = true;
    },
  },
};
</script>
