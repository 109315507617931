<template>
  <div class="pt-3">
    <p class="caption">Service Request</p>

    <v-card>
      <v-card-text>
        <validation-provider v-slot="{ errors, valid }" name="Domain">
          <v-select
            autocomplete="off"
            label="Domain"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            color="primary"
            name="requestDomain"
            :items="serviceRequestDomainChoices"
            item-text="label"
            item-value="value"
            v-model="cityworksIntegrationSettings.service_request_domain_id"
            @change="
              cityworksIntegrationSettings.service_request_template_id =
                undefined;
              getCityworksRequestTemplates();
              $emit(
                'cityworks-integration-settings-changed',
                cityworksIntegrationSettings
              );
            "
          >
          </v-select>
        </validation-provider>

        <validation-provider v-slot="{ errors, valid }" name="Template">
          <v-select
            autocomplete="off"
            label="Template"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            color="primary"
            name="requestTemplate"
            :items="serviceRequestTemplateChoices"
            item-text="label"
            item-value="value"
            v-model="cityworksIntegrationSettings.service_request_template_id"
            @change="
              $emit(
                'cityworks-integration-settings-changed',
                cityworksIntegrationSettings
              )
            "
          >
          </v-select>
        </validation-provider>

        <v-switch
          label="Attach PDF"
          v-model="cityworksIntegrationSettings.attach_pdf"
          @change="
            $emit(
              'cityworks-integration-settings-changed',
              cityworksIntegrationSettings
            )
          "
          hide-details
        ></v-switch>

        <v-switch
          label="Attach Photos"
          v-model="cityworksIntegrationSettings.attach_photos"
          @change="
            $emit(
              'cityworks-integration-settings-changed',
              cityworksIntegrationSettings
            )
          "
          hide-details
        ></v-switch>

        <div>
          To add Labor to the service request, please configure it on the form
          used to close the ticket.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import authObjectMixin from "@/mixins/authObjectMixin";
import { mdiInformation } from "@mdi/js";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Save811TicketsToCityworks",
  computed: {
    serviceRequestDomainChoices() {
      return this.domains.map((d) => {
        const { DomainId, Description } = d;
        return {
          value: DomainId,
          label: Description,
        };
      });
    },
    serviceRequestTemplateChoices() {
      return this.requestTemplates.map((r) => {
        const { ProblemSid, ProblemCode } = r;
        return {
          value: ProblemSid,
          label: ProblemCode,
        };
      });
    },
  },
  data() {
    return {
      auth: {},
      hasValidCityworksToken: false,
      showCityworksSignInDialog: false,
      cityworksIntegrationSettings: {
        service_request_domain_id: undefined,
        service_request_domain_desc: "",
        service_request_template_id: undefined,
        service_request_template_desc: "",
        attach_pdf: undefined,
        attach_photos: undefined,
      },
      cityworksInfo: {
        api_url: "",
        cityworks_save_locate_request: false,
      },
      domains: [],
      requestTemplates: [],
      mdiInformation,
    };
  },
  props: {
    cityworksIntegrationInfo: Object,
  },
  watch: {
    cityworksIntegrationInfo: {
      deep: true,
      handler(val) {
        this.cityworksInfo = { ...val };
      },
    },
  },
  async beforeMount() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.cityworksInfo = { ...this.cityworksIntegrationInfo };
    await this.validateCityworksToken();
    await this.getCityworksLocateRequestSettings();
    await this.getCityworksDomains();
    await this.getCityworksRequestTemplates();
  },
  mixins: [authObjectMixin],
  methods: {
    async validateCityworksToken() {
      try {
        const {
          data: {
            results: { service_request_token: serviceRequestToken },
          },
        } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
        this.hasValidCityworksToken = Boolean(serviceRequestToken);
      } catch (error) {
        this.hasValidCityworksToken = false;
      }
    },
    async updateCityworksToken() {
      await this.validateCityworksToken();
      await this.getCityworksDomains();
      await this.getCityworksRequestTemplates();
    },
    async getCityworksDomains() {
      if (!this.hasValidCityworksToken) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/domains`, {
        params: {
          use_811_service_request_token: true,
        },
      });
      this.domains = results;
    },
    async getCityworksRequestTemplates() {
      if (
        !this.cityworksIntegrationSettings.service_request_domain_id ||
        !this.hasValidCityworksToken
      ) {
        return;
      }
      try {
        const {
          data: { results },
        } = await axios.get(`${APIURL}/cityworks/problems`, {
          params: {
            domain_id:
              this.cityworksIntegrationSettings.service_request_domain_id,
            use_811_service_request_token: true,
          },
        });
        this.requestTemplates = results;
      } catch (error) {
        this.requestTemplates = [];
      }
    },
    async getCityworksLocateRequestSettings() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
      this.cityworksIntegrationSettings = results;
      const {
        service_request_domain_id: serviceReqDomainId,
        service_request_domain_desc: serviceReqDomainDesc,
        service_request_template_id: serviceReqTemplateId,
        service_request_template_desc: serviceReqTemplateDesc,
        attach_pdf: attachPdf,
        attach_photos: attachPhotos,
      } = this.cityworksIntegrationSettings;
      const settings = {
        request_domain_id: serviceReqDomainId,
        service_request_domain_desc: serviceReqDomainDesc,
        service_request_template_id: serviceReqTemplateId,
        service_request_template_desc: serviceReqTemplateDesc,
        attach_pdf: attachPdf,
        attach_photos: attachPhotos,
      };
      await this.getCityworksRequestTemplates();
      this.$emit("cityworks-integration-settings-changed", settings);
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
