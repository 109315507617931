<template>
  <v-dialog
    :value="showEditUserDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
  >
    <v-card style="background-color: #f1f2f1">
      <v-toolbar dark color="primary" ref="toolbar" class="elevation-0">
        <v-toolbar-title>Edit User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('edit-user-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '65vh',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <validation-observer ref="editUserForm">
          <form>
            <InformationForm
              :selectedUser="selectedUser"
              :userGroup="userGroup"
              @input="onInformationFormSaved"
            />

            <PermissionsForm
              :selectedUser="selectedUser"
              :userBeingEdited="user"
              :userGroup="userGroup"
              @input="onPermissionsFormSaved"
            />

            <NotificationsForm
              v-if="
                userGroup.access_field_reports_app ||
                userGroup.access_811_tms_app
              "
              :selectedUser="selectedUser"
              :inspectAccess="userGroup.access_field_reports_app"
              :tmsAccess="userGroup.access_811_tms_app"
            />
          </form>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end py-5 px-7" ref="cardAction">
        <v-btn
          id="userSaveButton"
          @click="saveUser"
          color="primary"
          :disabled="!canManageUsers"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InformationForm from "@/components/organization/users/edit-user-dialog/InformationForm";
import NotificationsForm from "@/components/organization/users/edit-user-dialog/NotificationsForm";
import PermissionsForm from "@/components/organization/users/edit-user-dialog/PermissionsForm";
import axios from "axios";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "EditUserDialog",
  props: {
    showEditUserDialog: Boolean,
    userGroup: Object,
    selectedUser: Object,
  },
  components: {
    InformationForm,
    NotificationsForm,
    PermissionsForm,
  },
  mixins: [contentHeightMixin, permissionsMixin],
  beforeMount() {
    const {
      username,
      f_name: firstName,
      l_name: lastName,
      email,
      is_gis_admin: isAdmin,
      is_power_user: isPowerUser,
      is_view_only_user: isViewOnlyUser,
      is_standard_user: isStandardUser,
      locate_requests_user: isLocator,
      time_zone: timeZone,
      last_map_opened: mapIdSelected,
      sms_notification: cellPhone = "",
      esri_username: esriUsername,
      is_active: isActive,
    } = { ...this.selectedUser };
    this.user = {
      ...this.user,
      username,
      firstName,
      lastName,
      email,
      isAdmin,
      isPowerUser,
      isViewOnlyUser,
      isStandardUser,
      isLocator,
      timeZone,
      mapIdSelected,
      cellPhone,
      esriUsername: esriUsername || null,
      isActive,
    };
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        username: "",
        esriUsername: "",
        email: "",
        cellPhone: "",
        timeZone: "",
        isAdmin: false,
        isViewOnlyUser: false,
        isPowerUser: false,
        isStandardUser: false,
        mapIdSelected: undefined,
        isActive: true,
      },
    };
  },
  methods: {
    onInformationFormSaved(user) {
      const {
        firstName,
        lastName,
        username,
        esriUsername,
        email,
        cellPhone,
        timeZone,
        mapIdSelected,
        isActive,
        isAdmin,
      } = user;
      this.user = {
        ...this.user,
        firstName,
        lastName,
        username,
        esriUsername,
        email,
        cellPhone,
        timeZone,
        mapIdSelected,
        isActive,
        isAdmin,
      };
    },
    onPermissionsFormSaved(user) {
      const {
        isViewOnlyUser,
        isPowerUser,
        isStandardUser,
        assignTickets,
        isLocator,
        viewTicketsAssignedToOthers,
        receive811Notifications,
        reportsToUserId,
        viewDashboard,
        manageUsers,
        manageMaps,
        manageLayers,
        manageForms,
        manage811Codes,
        editSchduledForms,
      } = user;
      this.user = {
        ...this.user,
        isViewOnlyUser,
        isPowerUser,
        isStandardUser,
        assignTickets,
        isLocator,
        viewTicketsAssignedToOthers,
        receive811Notifications,
        reportsToUserId,
        viewDashboard,
        manageUsers,
        manageMaps,
        manageLayers,
        manageForms,
        manage811Codes,
        editSchduledForms,
      };
    },
    async saveUser() {
      const success = await this.$refs.editUserForm.validate();
      if (!success) {
        return;
      }
      const {
        username,
        firstName,
        lastName,
        email,
        isAdmin,
        isPowerUser,
        isViewOnlyUser,
        isStandardUser,
        isLocator,
        timeZone,
        mapIdSelected,
        cellPhone,
        esriUsername,
        isActive,
        assignTickets,
        viewTicketsAssignedToOthers,
        receive811Notifications,
        reportsToUserId,
        viewDashboard,
        manageUsers,
        manageMaps,
        manageLayers,
        manageForms,
        manage811Codes,
        editSchduledForms,
      } = this.user;
      const payload = {
        username,
        f_name: firstName,
        l_name: lastName,
        email,
        is_gis_admin: isAdmin,
        is_power_user: isPowerUser,
        is_view_only_user: isViewOnlyUser,
        is_standard_user: isStandardUser,
        locate_requests_user: isLocator,
        time_zone: timeZone,
        last_map_opened: mapIdSelected,
        sms_notification: cellPhone
          ? cellPhone?.toString()?.replace(/\D/g, "")
          : undefined,
        esri_username: esriUsername || null,
        is_active: isActive,
        hide_tutorial_menu: false,
        assign_tickets: assignTickets,
        view_tickets_assigned_to_others: viewTicketsAssignedToOthers,
        receive_811_notifications: receive811Notifications,
        reports_to_user_id: reportsToUserId,
        view_dashboard: viewDashboard,
        manage_users: manageUsers,
        manage_maps: manageMaps,
        manage_layers: manageLayers,
        manage_forms: manageForms,
        manage_811_codes: manage811Codes,
        edit_scheduled_forms: editSchduledForms,
      };
      await axios.put(`${APIURL}/users/${this.selectedUser.user_id}`, payload);
      this.$emit("user-saved");
    },
  },
};
</script>
