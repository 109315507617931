<template>
  <div class="pt-3">
    <p class="caption">Cityworks Account</p>

    <v-card>
      <v-card-text>
        <div>
          Sign in using the account that will be responsible for creating
          Cityworks service requests.
        </div>

        <div v-if="hasValidCityworksToken" class="d-flex align-center gap">
          <div class="text-body-1">Signed In</div>
          <v-btn text color="primary" @click="signOutOfCityworks">
            Sign Out
          </v-btn>
        </div>
        <div v-else class="d-flex align-center gap">
          <div class="text-body-1">Not Signed In</div>
          <v-btn text color="primary" @click="showCityworksSignInDialog = true">
            Sign In
          </v-btn>

          <CityworksSignInDialog
            v-if="showCityworksSignInDialog"
            :cityworksIntegrationInfo="cityworksIntegrationInfo"
            :showCityworksSignInDialog="showCityworksSignInDialog"
            @cityworks-sign-in-dialog-close="showCityworksSignInDialog = false"
            @cityworks-sign-in-dialog-succeed="
              showCityworksSignInDialog = false;
              updateCityworksToken();
            "
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import authObjectMixin from "@/mixins/authObjectMixin";
import CityworksSignInDialog from "@/components/organization/integrations/shared/cityworks-integration-dialog/save-811-tickets-to-cityworks/CityworksSignInDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SignIn",
  data() {
    return {
      showCityworksSignInDialog: false,
      hasValidCityworksToken: false,
      cityworksInfo: {
        api_url: "",
      },
      auth: {},
    };
  },
  components: {
    CityworksSignInDialog,
  },
  props: {
    cityworksIntegrationInfo: Object,
  },
  mixins: [authObjectMixin],
  async beforeMount() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.cityworksInfo = { ...this.cityworksIntegrationInfo };
    await this.validateCityworksToken();
  },
  watch: {
    cityworksIntegrationInfo: {
      deep: true,
      handler(val) {
        this.cityworksInfo = { ...val };
      },
    },
  },
  methods: {
    async validateCityworksToken() {
      try {
        const {
          data: {
            results: { service_request_token: serviceRequestToken },
          },
        } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
        this.hasValidCityworksToken = Boolean(serviceRequestToken);
      } catch (error) {
        this.hasValidCityworksToken = false;
      }
    },
    async signOutOfCityworks() {
      const { user_id: userId } = this.auth;
      await axios.post(
        `${APIURL}/cityworks/service_request_sign_out`,
        undefined,
        {
          params: {
            user_id: userId,
          },
        }
      );
      await this.updateCityworksToken();
    },
    async updateCityworksToken() {
      await this.updateAuthObject();
      await this.validateCityworksToken();
      this.$emit("sign-in-status-changed");
    },
  },
};
</script>
