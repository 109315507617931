var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "py-0 mb-2 mt-4" }, [_vm._v("Notifications")]),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0 mx-0" },
            [
              _c(
                "v-simple-table",
                { attrs: { id: "userNotificationSettings" } },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Event")]),
                      _c("th", [_vm._v("In-App")]),
                      _c("th", [_vm._v("Email")]),
                      _c("th", [_vm._v("Text")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.userNotificationSettings, function (n) {
                      return _c(
                        "tr",
                        { key: n.notification_type, staticClass: "py-0 my-0" },
                        [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("capitalize")(n.notification_type)
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              n.notification_type !== "ticket_assigned"
                                ? _c("v-checkbox", {
                                    attrs: { disabled: !_vm.canManageUsers },
                                    on: {
                                      change: _vm.saveUserNotificationSettings,
                                    },
                                    model: {
                                      value: n.in_app,
                                      callback: function ($$v) {
                                        _vm.$set(n, "in_app", $$v)
                                      },
                                      expression: "n.in_app",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-checkbox", {
                                attrs: { disabled: !_vm.canManageUsers },
                                on: {
                                  change: _vm.saveUserNotificationSettings,
                                },
                                model: {
                                  value: n.email,
                                  callback: function ($$v) {
                                    _vm.$set(n, "email", $$v)
                                  },
                                  expression: "n.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("v-checkbox", {
                                attrs: { disabled: !_vm.canManageUsers },
                                on: {
                                  change: _vm.saveUserNotificationSettings,
                                },
                                model: {
                                  value: n.sms,
                                  callback: function ($$v) {
                                    _vm.$set(n, "sms", $$v)
                                  },
                                  expression: "n.sms",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }