<template>
  <div class="my-3" style="width: 750px; max-width: 750px">
    <p class="caption">
      <v-icon>
        {{ mdiAccountMultiple }}
      </v-icon>
      Users
    </p>

    <v-card width="750px" max-width="750px">
      <v-card-text class="px-0 pb-0 pt-2">
        <div
          class="px-3 mt-0 mb-3 d-flex justify-space-between gap align-center"
        >
          <div style="width: 100%">
            <v-text-field
              v-model="keyword"
              label="Search"
              hide-details="auto"
              color="primary"
              name="keyword"
              class="pb-2 mb-1"
            >
              <template #append>
                <v-icon> {{ mdiMagnify }} </v-icon>
              </template>
            </v-text-field>
            <v-chip
              id="activeInactiveUsersChip"
              @click="showActiveUsers = !showActiveUsers"
            >
              {{ showActiveUsers ? "Active Users" : "Inactive Users" }}
              <v-btn icon x-small class="ml-1 mr-n1">
                <v-icon>
                  {{ mdiSyncCircle }}
                </v-icon>
              </v-btn>
            </v-chip>
          </div>
          <div>
            <div>
              <v-btn
                text
                color="primary"
                @click="showAddUserDialog = true"
                id="addUserBtn"
                :disabled="!canManageUsers"
              >
                <v-icon class="mr-1" small>
                  {{ mdiPlus }}
                </v-icon>
                User
              </v-btn>
            </div>
            <div>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text color="primary" v-bind="attrs" v-on="on">
                    <v-icon class="mr-1">
                      {{ mdiPlusCircle }}
                    </v-icon>
                    Fields
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <v-checkbox
                      v-for="h of nonEmptyHeaders"
                      :key="h.value"
                      @click.stop
                      v-model="headersEnabled[h.value]"
                      :label="h.text"
                      class="py-0 my-0"
                    >
                    </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
        </div>

        <v-data-table
          :headers="filteredHeaders"
          :items="filteredUsers"
          hide-default-footer
          disable-pagination
          item-key="user_id"
          :search="keyword"
          @click:row="onRowClick"
          class="cursor-pointer"
          id="filteredUsers"
        >
          <template v-slot:[`item.last_map_opened`]="{ item }">
            {{ getMapName(item.last_map_opened) }}
          </template>
          <template v-slot:[`item.is_gis_admin`]="{ item }">
            <v-icon v-if="item.is_gis_admin" color="#50A859">
              {{ mdiCheck }}
            </v-icon>
            <v-icon v-else color="#FF0000">
              {{ mdiClose }}
            </v-icon>
          </template>

          <template v-slot:[`item.is_power_user`]="{ item }">
            <v-icon v-if="item.is_power_user" color="#50A859">
              {{ mdiCheck }}
            </v-icon>
            <v-icon v-else color="#FF0000">
              {{ mdiClose }}
            </v-icon>
          </template>

          <template v-slot:[`item.locate_requests_user`]="{ item }">
            <v-icon v-if="item.locate_requests_user" color="#50A859">
              {{ mdiCheck }}
            </v-icon>
            <v-icon v-else color="#FF0000">
              {{ mdiClose }}
            </v-icon>
          </template>

          <template v-slot:[`item.user_id`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    {{ mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list class="py-0">
                <v-list-item
                  class="py-1"
                  @click="
                    showEditUserDialog = true;
                    selectedUser = item;
                  "
                >
                  <v-list-item-action-text class="d-flex align-center">
                    <div class="pr-1">
                      <v-icon color="primary"> {{ mdiAccountEdit }} </v-icon>
                    </div>
                    <div class="overflowText">Edit User</div>
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <AddUserDialog
          :showAddUserDialog="showAddUserDialog"
          :userGroup="userGroup"
          v-if="showAddUserDialog"
          @add-user-dialog-close="showAddUserDialog = false"
          @user-created="
            showAddUserDialog = false;
            getUserGroupUsers();
          "
        />

        <EditUserDialog
          :showEditUserDialog="showEditUserDialog"
          :userGroup="userGroup"
          :selectedUser="selectedUser"
          v-if="showEditUserDialog"
          @edit-user-dialog-close="showEditUserDialog = false"
          @user-saved="
            showEditUserDialog = false;
            getUserGroupUsers();
          "
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountMultiple,
  mdiCheck,
  mdiClose,
  mdiSyncCircle,
  mdiMagnify,
  mdiPlus,
  mdiPlusCircle,
  mdiDotsVertical,
  mdiAccountEdit,
} from "@mdi/js";
import axios from "axios";
import AddUserDialog from "@/components/organization/users/AddUserDialog";
import EditUserDialog from "@/components/organization/users/EditUserDialog";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "First Name",
    align: "start",
    sortable: false,
    value: "f_name",
  },
  {
    text: "Last Name",
    align: "start",
    sortable: false,
    value: "l_name",
  },
  {
    text: "Username",
    align: "start",
    sortable: false,
    value: "username",
  },
  {
    text: "Inspect User Type",
    align: "start",
    sortable: false,
    value: "userTypeName",
  },
  {
    text: "Admin",
    align: "start",
    sortable: false,
    value: "is_gis_admin",
  },
  {
    text: "811 Locator",
    align: "start",
    sortable: false,
    value: "locate_requests_user",
  },
  {
    text: "Email",
    align: "start",
    sortable: false,
    value: "email",
  },
  {
    text: "Cell Phone",
    align: "start",
    sortable: false,
    value: "sms_notification",
  },
  {
    text: "Time Zone",
    align: "start",
    sortable: false,
    value: "time_zone",
  },
  {
    text: "ArcGIS Username",
    align: "start",
    sortable: false,
    value: "esri_username",
  },
  {
    text: "Map to Load After Sign In",
    align: "start",
    sortable: false,
    value: "last_map_opened",
  },
  {
    text: "",
    align: "end",
    sortable: false,
    value: "user_id",
  },
];

export default {
  name: "Users",
  props: {
    userGroup: Object,
  },
  components: {
    AddUserDialog,
    EditUserDialog,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      mdiAccountMultiple,
      mdiCheck,
      mdiClose,
      mdiSyncCircle,
      mdiMagnify,
      mdiPlus,
      mdiPlusCircle,
      mdiDotsVertical,
      mdiAccountEdit,
      users: [],
      headers,
      showActiveUsers: true,
      keyword: "",
      showAddUserDialog: false,
      showEditUserDialog: false,
      selectedUser: {},
      maps: [],
      headersEnabled: Object.fromEntries(
        headers.map(({ value }) => {
          if (
            [
              "f_name",
              "l_name",
              "username",
              "userTypeName",
              "is_gis_admin",
              "is_power_user",
              "locate_requests_user",
              "user_id",
            ].includes(value)
          ) {
            return [value, true];
          }
          return [value, false];
        })
      ),
    };
  },
  computed: {
    nonEmptyHeaders() {
      return this.headers.filter((h) => {
        return Boolean(h.text);
      });
    },
    filteredHeaders() {
      if (!this.userGroup.access_811_tms_app) {
        return this.headers.filter((h) => {
          return (
            this.headersEnabled[h.value] && h.value !== "locate_requests_user"
          );
        });
      }
      return this.headers.filter((h) => {
        return this.headersEnabled[h.value];
      });
    },
    filteredUsers() {
      return this.mappedUsers.filter(
        (u) => u.is_active === this.showActiveUsers
      );
    },
    mappedUsers() {
      return this.users.map((u) => {
        const {
          is_standard_user: isStandardUser,
          is_view_only_user: isViewOnlyUser,
          is_power_user: isPowerUser,
        } = u;
        let userTypeName;
        if (isStandardUser) {
          userTypeName = "Standard";
        } else if (isViewOnlyUser) {
          userTypeName = "View Only";
        } else if (isPowerUser) {
          userTypeName = "Power";
        }
        return {
          ...u,
          userTypeName,
        };
      });
    },
  },
  methods: {
    async getUserGroupUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results.filter((r) => !r.is_contact);
      this.$emit("user-array", this.users);
    },
    onRowClick(item) {
      this.showEditUserDialog = true;
      this.selectedUser = item;
    },
    getMapName(mapId) {
      if (mapId !== null) {
        const map = this.maps.find((m) => m.map_id === mapId);
        return map.name;
      }
      return "";
    },
    async getAllMaps() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/maps`);
      this.maps = results;
    },
  },
  beforeMount() {
    this.getAllMaps();
    this.getUserGroupUsers();
    if (!this.userGroup.access_811_tms_app) {
      this.headersEnabled = Object.fromEntries(
        headers.map(({ value }) => {
          if (
            [
              "f_name",
              "l_name",
              "username",
              "userTypeName",
              "is_gis_admin",
              "is_power_user",
              "user_id",
            ].includes(value)
          ) {
            return [value, true];
          }
          return [value, false];
        })
      );
    }
  },
};
</script>

<style scoped>
.gap {
  gap: 20px;
}

.overflowText {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
  color: #286054;
}
</style>
