var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "elevation-2 mb-3",
          attrs: { fab: "", color: "primary", small: "" },
        },
        [_c("img", { attrs: { src: require("@/assets/UtiliBot-white.svg") } })]
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWelcomeMsg,
                  expression: "showWelcomeMsg",
                },
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Hi! I'm the Create User UtiliBot and I will guide you through the process. Let's begin. "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFirstNameMsg,
                  expression: "showFirstNameMsg",
                },
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" What is the user's first name? ")]
          ),
        ],
        1
      ),
      !_vm.firstNameSubmitted
        ? _c(
            "div",
            [
              _c("validation-observer", { ref: "firstNameForm" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.saveFirstName.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "validation-provider",
                      { attrs: { rules: "required" } },
                      [
                        _c("v-text-field", {
                          ref: "firstNameInput",
                          attrs: {
                            outlined: "",
                            color: "#E5E5E5",
                            "hide-details": "auto",
                            name: "serviceName",
                            "single-line": "",
                            "background-color": "white",
                            "full-width": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mt-n2" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              type: "submit",
                                              color: "primary",
                                              disabled: !_vm.user.firstName,
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                " " + _vm._s(_vm.mdiSend) + " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3939529708
                          ),
                          model: {
                            value: _vm.user.firstName,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "firstName", $$v)
                            },
                            expression: "user.firstName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    outlined: "",
                    rounded: "",
                    width: "fit-content",
                    dense: "",
                  },
                },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.user.firstName) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLastNameMsg,
                  expression: "showLastNameMsg",
                },
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" What is the user's last name? ")]
          ),
        ],
        1
      ),
      _vm.showLastNameMsg
        ? _c("div", [
            !_vm.lastNameSubmitted
              ? _c(
                  "div",
                  [
                    _c("validation-observer", { ref: "lastNameForm" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.saveLastName.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "validation-provider",
                            { attrs: { rules: "required" } },
                            [
                              _c("v-text-field", {
                                ref: "lastNameInput",
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "lastName",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "primary",
                                                    disabled:
                                                      !_vm.user.lastName,
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1696545916
                                ),
                                model: {
                                  value: _vm.user.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "lastName", $$v)
                                  },
                                  expression: "user.lastName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-list",
                      {
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: "",
                        },
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.user.lastName) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showUserNameMsg,
                  expression: "showUserNameMsg",
                },
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Username for " +
                  _vm._s(_vm.user.firstName) +
                  "? It needs to be at least 6 characters long and can only have lowercase letters and numbers (no spaces or special characters). "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.showUserNameMsg
        ? _c("div", [
            !_vm.userNameSubmitted
              ? _c(
                  "div",
                  [
                    _c("validation-observer", { ref: "userNameForm" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.saveUserName.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "validation-provider",
                            {
                              attrs: {
                                rules: { required: true, regex: /^.{6,}$/ },
                              },
                            },
                            [
                              _c("v-text-field", {
                                ref: "usernameInput",
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "username",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": "",
                                },
                                on: { input: _vm.validateUsername },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "primary",
                                                    disabled:
                                                      !_vm.usernameValid,
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  322451502
                                ),
                                model: {
                                  value: _vm.user.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "username", $$v)
                                  },
                                  expression: "user.username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end my-5" },
                  [
                    _c(
                      "v-list",
                      {
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: "",
                        },
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.user.username) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _vm._l(_vm.showRepeatUsernameFormTimes, function (t) {
        return _c("RepeatUserNameForm", {
          key: t,
          attrs: { index: t },
          on: {
            "step-1-finished": function ($event) {
              return _vm.$emit("step-1-finished", { ..._vm.user, ...$event })
            },
            "show-repeat-username-form-again": function ($event) {
              _vm.showRepeatUsernameFormTimes++
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }