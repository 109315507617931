<template>
  <v-dialog
    :value="showConfirmAddAllLayerDepartmentsDialog"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Layer Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-2">
        The map(s) selected have layers that do not currently have access to
        this department. If you proceed, this department will be added to the
        layers.
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('confirm')">
          SAVE DEPARTMENT AND UPDATE ALL LAYER DEPARTMENTS
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmAddAllLayerDepartmentsDialog",
  props: {
    showConfirmAddAllLayerDepartmentsDialog: Boolean,
  },
};
</script>
