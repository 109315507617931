import { render, staticRenderFns } from "./Departments.vue?vue&type=template&id=769d05f5&scoped=true&"
import script from "./Departments.vue?vue&type=script&lang=js&"
export * from "./Departments.vue?vue&type=script&lang=js&"
import style0 from "./Departments.vue?vue&type=style&index=0&id=769d05f5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "769d05f5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('769d05f5')) {
      api.createRecord('769d05f5', component.options)
    } else {
      api.reload('769d05f5', component.options)
    }
    module.hot.accept("./Departments.vue?vue&type=template&id=769d05f5&scoped=true&", function () {
      api.rerender('769d05f5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organization/Departments.vue"
export default component.exports