export default {
  computed: {
    canManageUsers() {
      return this.currentUserPermissions.manageUsers ?? true;
    },
    canManageMaps() {
      return this.currentUserPermissions.manageMaps ?? true;
    },
    canManageLayers() {
      return this.currentUserPermissions.manageLayers ?? true;
    },
    canManageForms() {
      return this.currentUserPermissions.manageForms ?? true;
    },
    canManage811Codes() {
      return this.currentUserPermissions.manageManage811Codes ?? true;
    },
  },
  data() {
    return {
      currentUserPermissions: {},
    };
  },
  methods: {
    getPermissions() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const {
        manage_users: manageUsers,
        manage_maps: manageMaps,
        manage_layers: manageLayers,
        manage_forms: manageForms,
        manage_811_codes: manageManage811Codes,
      } = auth;
      this.currentUserPermissions = {
        manageUsers,
        manageMaps,
        manageLayers,
        manageForms,
        manageManage811Codes,
      };
    },
  },
  beforeMount() {
    this.getPermissions();
  },
};
