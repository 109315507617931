<template>
  <div>
    <p class="caption mb-2">
      <v-icon size="16px">
        {{ mdiChartDonut }}
      </v-icon>
      Licensing Plan and Usage
    </p>
    <v-card
      width="750px"
      max-width="750px"
      v-if="
        licensing.user_group.access_811_tms_app ||
        licensing.user_group.access_field_reports_app
      "
    >
      <v-card-text
        class="d-flex justify-space-between"
        v-if="licensing.user_group.access_811_tms_app"
      >
        <div class="d-flex align-center" style="margin-left: -4px">
          <img src="@/assets/UtiliSyncLogoTMS.svg" height="75px" width="auto" />
        </div>
        <table style="width: 70%">
          <col width="35%" />
          <col width="25%" />
          <col width="20%" />
          <col width="20%" />
          <tr>
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiCalendarRefresh }} </v-icon
                >Renewal Date</span
              >
            </td>
            <td>
              <strong
                :style="renewalOverdue ? 'color:#ff6060' : 'color:#286054'"
                >{{ licensing.user_group.plan_renewal | formatDateUTC }}</strong
              >
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiCreditCardOutline }} </v-icon
                >Payment Method</span
              >
            </td>
            <td>
              <strong style="color: #286054">{{
                licensing.user_group.payment_type
                  ? licensing.user_group.payment_type
                  : "Not Specified"
              }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="20px" style="margin: 0 2px 0 -2px">
                  {{ mdiAutoFix }} </v-icon
                >Route Optimization</span
              >
            </td>
            <td>
              <strong style="color: #286054">{{
                licensing.user_group.route_optimization_811
                  ? "Included"
                  : "Not Included"
              }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="20px" style="margin: 0 2px 0 -2px">
                  {{ mdiCity }} </v-icon
                >Cityworks Integration</span
              >
            </td>
            <td>
              <strong style="color: #286054">{{
                licensing.user_group.access_cityworks_811_integration
                  ? "Included"
                  : "Not Included"
              }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiMapMarkerMultiple }} </v-icon
                >Tickets Received</span
              >
            </td>
            <td>
              <strong
                :style="
                  percentageUsedTickets > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ numTickets }} Received
              </strong>
            </td>
            <td class="px-0">
              <div style="width: 100%">
                <v-progress-linear
                  :value="percentageUsedTickets"
                  height="14"
                  :color="percentageUsedTickets > 100 ? '#ff6060' : 'primary'"
                ></v-progress-linear>
              </div>
            </td>
            <td>
              <strong
                :style="
                  percentageUsedTickets > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ licensing.user_group.number_of_811_tickets }} Total
              </strong>
            </td>
          </tr>
        </table>
      </v-card-text>
      <hr
        color="#E0E0E0"
        style="border-width: 0.25px"
        v-if="licensing.user_group.access_field_reports_app"
      />
      <v-card-text
        class="d-flex justify-space-between"
        v-if="licensing.user_group.access_field_reports_app"
      >
        <div class="d-flex align-center">
          <img
            src="@/assets/UtiliSyncLogoInspect.svg"
            height="50px"
            width="auto"
          />
        </div>
        <table style="width: 70%">
          <col width="35%" />
          <col width="25%" />
          <col width="20%" />
          <col width="20%" />
          <tr>
            <td>
              <span
                ><v-icon size="16px" class="mr-1"> {{ mdiInformation }} </v-icon
                >Plan Type</span
              >
            </td>
            <td>
              <strong style="color: #286054">{{ inspectPlanType }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiCalendarRefresh }} </v-icon
                >Renewal Date</span
              >
            </td>
            <td>
              <strong
                :style="renewalOverdue ? 'color:#ff6060' : 'color:#286054'"
                >{{ licensing.user_group.plan_renewal | formatDateUTC }}</strong
              >
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiCreditCardOutline }} </v-icon
                >Payment Method</span
              >
            </td>
            <td>
              <strong style="color: #286054">{{
                licensing.user_group.payment_type
                  ? licensing.user_group.payment_type
                  : "Not Specified"
              }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="20px" style="margin: 0 2px 0 -2px">
                  {{ mdiMapMarkerAccount }} </v-icon
                >Site Contacts</span
              >
            </td>
            <td>
              <strong style="color: #286054">{{
                licensing.user_group.paying_for_linked_sites
                  ? "Included"
                  : "Not Included"
              }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <span
                ><v-icon size="20px" style="margin: 0 2px 0 -2px">
                  {{ mdiCity }} </v-icon
                >Cityworks Integration</span
              >
            </td>
            <td>
              <strong style="color: #286054">{{
                licensing.user_group.access_cityworks_ams_integration
                  ? "Included"
                  : "Not Included"
              }}</strong>
            </td>
          </tr>
          <tr
            v-if="
              licensing.user_group.field_reports_plan_type == 'organization'
            "
          >
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiFileDocumentMultipleOutline }} </v-icon
                >Form Submissions</span
              >
            </td>
            <td>
              <strong
                :style="
                  percentageUsedForms > 100 ? 'color:#ff6060' : 'color:#286054'
                "
              >
                {{ numFormSubmissions }} Active
              </strong>
            </td>
            <td class="px-0">
              <div style="width: 100%">
                <v-progress-linear
                  :value="percentageUsedForms"
                  height="14"
                  :color="percentageUsedForms > 100 ? '#ff6060' : 'primary'"
                ></v-progress-linear>
              </div>
            </td>
            <td>
              <strong
                :style="
                  percentageUsedForms > 100 ? 'color:#ff6060' : 'color:#286054'
                "
              >
                {{ licensing.user_group.number_of_form_submissions }} Total
              </strong>
            </td>
          </tr>
          <tr v-if="licensing.user_group.field_reports_plan_type == 'sites'">
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiMapMarkerMultiple }} </v-icon
                >Sites Per Month</span
              >
            </td>
            <td>
              <strong
                :style="
                  percentageUsedActiveSites > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ numSites }} Active
              </strong>
            </td>
            <td class="px-0">
              <div style="width: 100%">
                <v-progress-linear
                  :value="percentageUsedActiveSites"
                  height="14"
                  :color="
                    percentageUsedActiveSites > 100 ? '#ff6060' : 'primary'
                  "
                ></v-progress-linear>
              </div>
            </td>
            <td>
              <strong
                :style="
                  percentageUsedActiveSites > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ licensing.user_group.number_of_active_sites }} Total
              </strong>
            </td>
          </tr>
          <tr
            v-if="
              licensing.user_group.field_reports_plan_type == 'organization' ||
              licensing.user_group.field_reports_plan_type == 'power_users'
            "
          >
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiAccountMultiple }} </v-icon
                >View Only Users</span
              >
            </td>
            <td>
              <strong
                :style="
                  percentageUsedViewOnly > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ numViewOnlyUsers }} Active
              </strong>
            </td>
            <td class="px-0">
              <div style="width: 100%">
                <v-progress-linear
                  :value="percentageUsedViewOnly"
                  height="14"
                  :color="percentageUsedViewOnly > 100 ? '#ff6060' : 'primary'"
                ></v-progress-linear>
              </div>
            </td>
            <td>
              <strong
                :style="
                  percentageUsedViewOnly > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ licensing.user_group.number_of_view_only_users }} Total
              </strong>
            </td>
          </tr>
          <tr
            v-if="licensing.user_group.field_reports_plan_type == 'power_users'"
          >
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiAccountMultiple }} </v-icon
                >Power Users</span
              >
            </td>
            <td>
              <strong
                :style="
                  percentageUsedPower > 100 ? 'color:#ff6060' : 'color:#286054'
                "
              >
                {{ numPowerUsers }} Active
              </strong>
            </td>
            <td class="px-0">
              <div style="width: 100%">
                <v-progress-linear
                  :value="percentageUsedPower"
                  height="14"
                  :color="percentageUsedPower > 100 ? '#ff6060' : 'primary'"
                ></v-progress-linear>
              </div>
            </td>
            <td>
              <strong
                :style="
                  percentageUsedPower > 100 ? 'color:#ff6060' : 'color:#286054'
                "
              >
                {{ licensing.user_group.number_of_power_users }} Total
              </strong>
            </td>
          </tr>
          <tr
            v-if="
              licensing.user_group.field_reports_plan_type == 'organization'
            "
          >
            <td>
              <span
                ><v-icon size="16px" class="mr-1">
                  {{ mdiAccountMultiple }} </v-icon
                >Standard Users</span
              >
            </td>
            <td>
              <strong
                :style="
                  percentageUsedStandard > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ numStandardUsers }} Active
              </strong>
            </td>
            <td class="px-0">
              <div style="width: 100%">
                <v-progress-linear
                  :value="percentageUsedStandard"
                  height="14"
                  :color="percentageUsedStandard > 100 ? '#ff6060' : 'primary'"
                ></v-progress-linear>
              </div>
            </td>
            <td>
              <strong
                :style="
                  percentageUsedStandard > 100
                    ? 'color:#ff6060'
                    : 'color:#286054'
                "
              >
                {{ licensing.user_group.number_of_standard_users }} Total
              </strong>
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-text v-if="!licensingLoaded">
        <div class="d-flex justify-center align-center">
          <v-progress-circular
            indeterminate
            size="16"
            color="primary"
          ></v-progress-circular>
          <div class="ml-2 font-weight-medium">
            Loading Licensing Plan and Usage
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <div class="font-weight-medium">No Licensing Available</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiMenuRight,
  mdiCalendarRefresh,
  mdiCreditCardOutline,
  mdiChartDonut,
  mdiMapMarkerMultiple,
  mdiFileDocumentMultipleOutline,
  mdiAccountMultiple,
  mdiMapMarkerAccount,
  mdiAutoFix,
  mdiInformation,
  mdiCity,
} from "@mdi/js";
import axios from "axios";
import moment from "moment";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "LicensingPlan",

  data() {
    return {
      mdiCreditCardOutline,
      mdiMenuRight,
      mdiCalendarRefresh,
      mdiChartDonut,
      mdiMapMarkerMultiple,
      mdiFileDocumentMultipleOutline,
      mdiAccountMultiple,
      mdiMapMarkerAccount,
      mdiAutoFix,
      mdiInformation,
      mdiCity,
      licensing: {
        user_group: {
          access_811_tms_app: false,
          access_field_reports_app: false,
        },
      },
      licensingLoaded: false,
    };
  },
  computed: {
    renewalOverdue() {
      const currentDate = moment().startOf("day");
      const planRenewalDate = moment(
        this.licensing.user_group.plan_renewal
      ).startOf("day");
      return currentDate.isAfter(planRenewalDate);
    },

    inspectPlanType() {
      if (this.licensing.user_group.field_reports_plan_type === "power_users") {
        return "Power Users";
      } else if (
        this.licensing.user_group.field_reports_plan_type === "sites"
      ) {
        return "Sites";
      } else if (
        this.licensing.user_group.field_reports_plan_type === "organization"
      ) {
        return "Organization";
      } else {
        return "Not Specified";
      }
    },
    numPowerUsers() {
      if (this.licensing.inspect_usage) {
        return this.licensing.inspect_usage.num_power_users;
      }
      return null;
    },
    numStandardUsers() {
      if (this.licensing.inspect_usage) {
        return this.licensing.inspect_usage.num_standard_users;
      }
      return null;
    },
    numViewOnlyUsers() {
      if (this.licensing.inspect_usage) {
        return this.licensing.inspect_usage.num_view_only_users;
      }
      return null;
    },
    numFormSubmissions() {
      if (this.licensing.inspect_usage) {
        return this.licensing.inspect_usage.num_form_submissions;
      }
      return null;
    },
    numSites() {
      if (this.licensing.inspect_usage) {
        return this.licensing.inspect_usage.active_sites;
      }
      return null;
    },
    numTickets() {
      if (this.licensing.tms_usage) {
        return this.licensing.tms_usage.num_tickets;
      }
      return null;
    },
    percentageUsedPower() {
      return Math.round(
        (this.numPowerUsers * 100) /
          this.licensing.user_group.number_of_power_users
      );
    },
    percentageUsedStandard() {
      return Math.round(
        (this.numStandardUsers * 100) /
          this.licensing.user_group.number_of_standard_users
      );
    },
    percentageUsedViewOnly() {
      return Math.round(
        (this.numViewOnlyUsers * 100) /
          this.licensing.user_group.number_of_view_only_users
      );
    },
    percentageUsedActiveSites() {
      return Math.round(
        (this.numSites * 100) / this.licensing.user_group.number_of_active_sites
      );
    },
    percentageUsedForms() {
      return Math.round(
        (this.numFormSubmissions * 100) /
          this.licensing.user_group.number_of_form_submissions
      );
    },
    percentageUsedTickets() {
      return Math.round(
        (this.numTickets * 100) /
          this.licensing.user_group.number_of_811_tickets
      );
    },
  },
  methods: {
    async getLicensingAndUsage() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_groups/licensing`);
      this.licensing = results;
    },
  },
  async beforeMount() {
    await this.getLicensingAndUsage();
    this.licensingLoaded = true;
  },
};
</script>

<style scoped>
.gap {
  gap: 4px;
}

td {
  padding: 0 4px 0 4px;
}

table td:nth-child(2) {
  text-align: right;
}
</style>
