<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        Do you want to make {{ savedUser.firstName }} an admin? Admins can
        create users, configure map and layers.
      </v-alert>
    </v-expand-transition>

    <div v-if="!adminChoiceSubmitted" class="d-flex justify-end">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
      >
        <v-list-item
          v-for="(c, i) of adminChoices"
          :key="c.label"
          :style="{
            'border-bottom':
              i !== adminChoices.length - 1 ? '1px solid primary' : '',
            'background-color': 'white',
          }"
          class="my-0 py-0"
          @click="
            user.isAdmin = c.value;
            adminChoiceSubmitted = true;
            saveAdminChoice();
          "
        >
          <span :style="{ color: 'primary' }">
            {{ c.label }}
          </span>
        </v-list-item>
      </v-list>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ selectedAdminChoiceLabel }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "Step6UserFormAdmin",
  props: {
    savedUser: Object,
  },
  computed: {
    selectedAdminChoiceLabel() {
      return this.adminChoices.find((a) => a.value === this.user.isAdmin)
        ?.label;
    },
    adminChoices() {
      return [
        {
          value: true,
          label: `Yes, make ${this.savedUser.firstName} an admin.`,
        },
        {
          value: false,
          label: `No, don't make ${this.savedUser.firstName} an admin.`,
        },
      ];
    },
  },
  data() {
    return {
      mdiSend,
      user: {
        isAdmin: "",
      },
      adminChoiceSubmitted: false,
    };
  },
  methods: {
    async saveAdminChoice() {
      await sleep(250);
      this.$emit("step-6-finished", this.user);
      this.adminChoiceSubmitted = true;
    },
  },
};
</script>
