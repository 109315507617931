<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        What departments is this user a member of?
      </v-alert>
    </v-expand-transition>

    <div class="d-flex justify-end">
      <form>
        <v-list
          :style="{
            width: 'fit-content',
            'border-radius': '1%',
            border: '1px solid primary',
          }"
          class="py-0 my-0"
          dense
        >
          <v-list-item
            v-for="c of departmentChoices"
            :key="c.value"
            class="d-flex align-center"
          >
            <v-checkbox
              v-model="selectedDepartmentIds"
              :value="c.value"
              dense
              hide-details
              class="py-0 my-0"
              name="selectedDepartmentIds"
            ></v-checkbox>
            <section>
              {{ c.label }}
            </section>
          </v-list-item>
        </v-list>

        <section class="py-2">
          <v-btn color="primary" @click="saveApp()">Next</v-btn>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "Step11UserDepartment",
  props: {
    savedUser: Object,
    departmentChoices: Array,
  },
  data() {
    return {
      mdiSend,
      selectedDepartmentIds: [],
    };
  },
  methods: {
    async saveApp() {
      await sleep(250);
      this.$emit("step-11-finished", this.selectedDepartmentIds);
    },
  },
  watch: {
    departmentChoices: {
      deep: true,
      immediate: true,
      handler(departmentChoices) {
        if (
          Array.isArray(departmentChoices) &&
          this.selectedDepartmentIds.length === 0
        ) {
          this.selectedDepartmentIds = this.departmentChoices.map(
            ({ value }) => value
          );
        }
      },
    },
  },
};
</script>
