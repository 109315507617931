var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAccount811AccessDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        { staticStyle: { "background-color": "#f1f2f1" } },
        [
          _c(
            "v-toolbar",
            { ref: "toolbar", attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Account Access")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("account-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "65vh",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _c("div", { staticClass: "py-5" }, [
                _c("b", [
                  _vm._v("The user has access to the following accounts:"),
                ]),
              ]),
              _c("v-data-table", {
                attrs: {
                  id: "user811AccountAccess",
                  "item-key": "locate_request_provider_account_id",
                  headers: _vm.headers,
                  "hide-default-footer": "",
                  "disable-pagination": "",
                  "fixed-header": "",
                  items: _vm.locateRequestProviderAccounts,
                  "show-select": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header[`data-table-select`]",
                    fn: function ({ on, props }) {
                      return [
                        _c(
                          "v-simple-checkbox",
                          _vm._g(
                            _vm._b(
                              { attrs: { disabled: !_vm.canManageUsers } },
                              "v-simple-checkbox",
                              props,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                  {
                    key: "item[`data-table-select`]",
                    fn: function ({ on, props }) {
                      return [
                        _c(
                          "v-simple-checkbox",
                          _vm._g(
                            _vm._b(
                              { attrs: { disabled: !_vm.canManageUsers } },
                              "v-simple-checkbox",
                              props,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedlocateRequestProviderAccounts,
                  callback: function ($$v) {
                    _vm.selectedlocateRequestProviderAccounts = $$v
                  },
                  expression: "selectedlocateRequestProviderAccounts",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end py-5 px-7" },
            [
              _c(
                "v-btn",
                {
                  attrs: { id: "saveUser811AccountAccess", color: "primary" },
                  on: { click: _vm.saveAccount811Access },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }