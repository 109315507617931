<template>
  <div>
    <p class="py-0 mt-4">Permissions</p>

    <v-card>
      <v-card-text>
        <div v-if="userGroup.access_811_tms_app">
          <section class="d-flex justify-space-between align-center py-0 my-0">
            <p class="py-0 mb-2">UtiliSync TMS</p>
          </section>

          <p class="py-0 my-0">User Can:</p>
          <v-switch
            v-model="user.assignTickets"
            label="Assign Tickets"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.isLocator"
            label="Be Assigned Tickets"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.viewTicketsAssignedToOthers"
            label="View Tickets Assigned to Others"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.receive811Notifications"
            label="Receive 811 Notifications"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.viewDashboard"
            label="View Ticket Dashboard"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>

          <validation-provider v-slot="{ errors, valid }" name="Reports To">
            <v-select
              label="Reports To"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="reportsTo"
              :items="userChoices"
              item-text="label"
              item-value="value"
              v-model="user.reportsToUserId"
              @change="$emit('input', user)"
              style="width: 150px"
              class="pb-4"
              :disabled="!canManageUsers"
            >
            </v-select>
          </validation-provider>
          <v-divider></v-divider>
          <Account811Form :selectedUser="selectedUser" />
        </div>

        <div v-if="userGroup.access_field_reports_app">
          <div class="d-flex justify-space-between align-center">
            <div class="py-0 mb-2 mt-4">UtiliSync Inspect</div>
          </div>

          <v-switch
            v-model="user.editSchduledForms"
            label="Edit Scheduled Forms"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>

          <validation-provider
            v-slot="{ errors, valid }"
            name="User Type"
            rules="required"
          >
            <v-select
              label="User Type"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="userType"
              :items="filteredUserTypeChoices"
              item-text="label"
              item-value="value"
              v-model="userTypeChoice"
              @change="onUserTypeChange"
              style="width: 150px"
              :class="{ 'pb-4': departments.length > 0 }"
              :disabled="!canManageUsers"
            >
            </v-select>
          </validation-provider>

          <v-divider v-if="departments.length > 0"></v-divider>

          <DepartmentForm
            v-if="departments.length > 0"
            :selectedUser="selectedUser"
          />
        </div>

        <div v-if="userBeingEdited.isAdmin">
          <div class="d-flex justify-space-between align-center">
            <div class="py-0 mb-2 mt-4">Admin</div>
          </div>

          <p class="py-0 my-0">User Can:</p>
          <v-switch
            v-model="user.manageUsers"
            label="Manage Users"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.manageMaps"
            label="Manage Maps"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.manageLayers"
            label="Manage Layers"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.manageForms"
            label="Manage Forms"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
          <v-switch
            v-model="user.manage811Codes"
            label="Manage 811 Codes"
            @change="$emit('input', user)"
            class="py-0 my-1"
            hide-details
            :disabled="!canManageUsers"
          >
          </v-switch>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { USER_TYPES, USER_TYPE_CHOICES } from "@/constants/userTypes";
import axios from "axios";
import Account811Form from "@/components/organization/users/edit-user-dialog/Account811Form";
import DepartmentForm from "@/components/organization/users/edit-user-dialog/DepartmentForm";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PermissionsForm",
  props: {
    selectedUser: Object,
    userGroup: Object,
    userBeingEdited: Object,
  },
  mixins: [permissionsMixin],
  components: { Account811Form, DepartmentForm },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        username: "",
        esriUsername: "",
        email: "",
        cellPhone: "",
        timeZone: "",
        isAdmin: false,
        isLocator: false,
        isViewOnlyUser: false,
        isPowerUser: false,
        isStandardUser: false,
        mapIdSelected: undefined,
        isActive: true,
      },
      users: [],
      userTypeChoice: undefined,
      USER_TYPE_CHOICES,
      departments: [],
    };
  },
  computed: {
    numStandardUsersAvailable() {
      return (
        (this.userGroup.number_of_standard_users ?? 0) -
        this.users.filter((u) => u.is_standard_user && u.is_active).length
      );
    },
    numPowerUsersAvailable() {
      return (
        this.userGroup.number_of_power_users -
        this.users.filter((u) => u.is_power_user && u.is_active).length
      );
    },
    numViewOnlyUsersAvailable() {
      return (
        this.userGroup.number_of_view_only_users -
        this.users.filter((u) => u.is_view_only_user && u.is_active).length
      );
    },
    filteredUserTypeChoices() {
      if (
        this.numPowerUsersAvailable > 0 &&
        this.numStandardUsersAvailable > 0
      ) {
        return USER_TYPE_CHOICES;
      } else if (this.numPowerUsersAvailable > 0) {
        return USER_TYPE_CHOICES.map((u) => ({
          ...u,
          disabled: !(
            u.value === USER_TYPES.VIEW_ONLY_USER ||
            u.value === USER_TYPES.POWER_USER
          ),
        }));
      } else if (this.numStandardUsersAvailable > 0) {
        return USER_TYPE_CHOICES.map((u) => ({
          ...u,
          disabled: !(
            u.value === USER_TYPES.VIEW_ONLY_USER ||
            u.value === USER_TYPES.STANDARD_USER
          ),
        }));
      } else {
        return USER_TYPE_CHOICES.map((u) => ({
          ...u,
          disabled: u.value !== USER_TYPES.VIEW_ONLY_USER,
        }));
      }
    },
    userChoices() {
      const users = this.users
        .filter((u) => !u.is_contact && u.is_active)
        .map((u) => {
          const { user_id: value, f_name: fName, l_name: lName } = u;
          return {
            label: `${fName} ${lName}`,
            value,
          };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
      const selectOne = { label: "Select One", value: null };
      return [selectOne, ...users];
    },
  },
  beforeMount() {
    const {
      username,
      f_name: firstName,
      l_name: lastName,
      email,
      is_gis_admin: isAdmin,
      is_power_user: isPowerUser,
      is_view_only_user: isViewOnlyUser,
      is_standard_user: isStandardUser,
      locate_requests_user: isLocator,
      time_zone: timeZone,
      last_map_opened: mapIdSelected,
      sms_notification: cellPhone,
      esri_username: esriUsername,
      is_active: isActive,
      assign_tickets: assignTickets,
      view_tickets_assigned_to_others: viewTicketsAssignedToOthers,
      receive_811_notifications: receive811Notifications,
      reports_to_user_id: reportsToUserId,
      view_dashboard: viewDashboard,
      manage_users: manageUsers,
      manage_maps: manageMaps,
      manage_layers: manageLayers,
      manage_forms: manageForms,
      manage_811_codes: manage811Codes,
      edit_scheduled_forms: editSchduledForms,
    } = this.selectedUser;
    this.user = {
      ...this.user,
      username,
      firstName,
      lastName,
      email,
      isAdmin,
      isPowerUser,
      isViewOnlyUser,
      isStandardUser,
      isLocator,
      timeZone,
      mapIdSelected,
      cellPhone,
      esriUsername,
      isActive,
      assignTickets,
      viewTicketsAssignedToOthers,
      receive811Notifications,
      reportsToUserId,
      viewDashboard,
      manageUsers,
      manageMaps,
      manageLayers,
      manageForms,
      manage811Codes,
      editSchduledForms,
    };
    this.$emit("input", this.user);

    if (this.user.isViewOnlyUser) {
      this.userTypeChoice = USER_TYPES.VIEW_ONLY_USER;
    } else if (this.user.isStandardUser) {
      this.userTypeChoice = USER_TYPES.STANDARD_USER;
    } else if (this.user.isPowerUser) {
      this.userTypeChoice = USER_TYPES.POWER_USER;
    }
    this.getUserGroupUsers();
    this.getDepartments();
  },
  methods: {
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async getUserGroupUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results;
    },
    onUserTypeChange() {
      if (this.userTypeChoice === USER_TYPES.VIEW_ONLY_USER) {
        this.user.isViewOnlyUser = true;
        this.user.isPowerUser = false;
        this.user.isStandardUser = false;
        this.$emit("input", this.user);
      } else if (this.userTypeChoice === USER_TYPES.STANDARD_USER) {
        this.user.isViewOnlyUser = false;
        this.user.isPowerUser = false;
        this.user.isStandardUser = true;
        this.$emit("input", this.user);
      } else if (this.userTypeChoice === USER_TYPES.POWER_USER) {
        this.user.isViewOnlyUser = false;
        this.user.isPowerUser = true;
        this.user.isStandardUser = false;
        this.$emit("input", this.user);
      }
    },
  },
};
</script>

<style scoped>
#userPermissions >>> .gap {
  gap: 50px;
}

.half {
  flex-basis: 50%;
}
</style>
