var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditDepartmentDialog,
        "max-width": "600px",
        persistent: "",
      },
    },
    [
      _c("validation-observer", {
        ref: "updateDepartmentForm",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ valid: isFormValid }) {
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "py-0",
                    staticStyle: { "background-color": "#f1f2f1" },
                  },
                  [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "primary" },
                      },
                      [
                        _c("v-toolbar-title", [_vm._v("Edit Department")]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "edit-departmeent-dialog-close"
                                )
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        ref: "cardText",
                        staticClass: "py-1",
                        style: {
                          "background-color": "#f1f2f1",
                          "overflow-y": "auto",
                          height: "65vh",
                          "min-height": "65vh",
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            ref: "cardInnerText",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "Department Name",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Department Name",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "departmentName",
                                            "full-width": false,
                                          },
                                          model: {
                                            value: _vm.name,
                                            callback: function ($$v) {
                                              _vm.name = $$v
                                            },
                                            expression: "name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "section",
                          { staticClass: "py-2" },
                          [
                            _c(
                              "section",
                              { ref: "tabs" },
                              [
                                _c(
                                  "v-tabs",
                                  {
                                    staticClass: "pb-2",
                                    attrs: {
                                      centered: "",
                                      "background-color": "#f1f2f1",
                                    },
                                    model: {
                                      value: _vm.tab,
                                      callback: function ($$v) {
                                        _vm.tab = $$v
                                      },
                                      expression: "tab",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-tab",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("Users")]
                                    ),
                                    _c(
                                      "v-tab",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("Maps")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tabs-items",
                              {
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              },
                              [
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { flat: "" } },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pa-0" },
                                          [
                                            _c("DepartmentUsersTable", {
                                              attrs: {
                                                departmentId: _vm.departmentId,
                                                height: `${_vm.tableHeight}px`,
                                              },
                                              model: {
                                                value: _vm.newUsersInDepartment,
                                                callback: function ($$v) {
                                                  _vm.newUsersInDepartment = $$v
                                                },
                                                expression:
                                                  "newUsersInDepartment",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { flat: "" } },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pa-0" },
                                          [
                                            _c("MapDepartmentsTable", {
                                              attrs: {
                                                departmentId: _vm.departmentId,
                                                height: `${_vm.tableHeight}px`,
                                              },
                                              model: {
                                                value: _vm.newMapsInDepartment,
                                                callback: function ($$v) {
                                                  _vm.newMapsInDepartment = $$v
                                                },
                                                expression:
                                                  "newMapsInDepartment",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("ConfirmUpdateAllLayerDepartmentsDialog", {
                          attrs: {
                            showConfirmUpdateAllLayerDepartmentsDialog:
                              _vm.showConfirmUpdateAllLayerDepartmentsDialog,
                          },
                          on: {
                            close: function ($event) {
                              _vm.showConfirmUpdateAllLayerDepartmentsDialog = false
                            },
                            confirm: function ($event) {
                              _vm.showConfirmUpdateAllLayerDepartmentsDialog = false
                              _vm.updateDepartment()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end px-5 py-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", disabled: !isFormValid },
                            on: { click: _vm.tryUpdateDepartment },
                          },
                          [_vm._v(" Save ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }