<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        Do you want to make {{ savedUser.firstName }} an 811 locator? 811
        locators can be assigned tickets and clear tickets.
      </v-alert>
    </v-expand-transition>

    <div v-if="!locatorChoiceSubmitted" class="d-flex justify-end my-5">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
      >
        <v-list-item
          v-for="(c, i) of locatorChoices"
          :key="c.label"
          :style="{
            'border-bottom':
              i !== locatorChoices.length - 1 ? '1px solid primary' : '',
            'background-color': 'white',
          }"
          class="my-0 py-0"
          @click="
            user.isLocator = c.value;
            locatorChoiceSubmitted = true;
            saveLocatorChoice();
          "
        >
          <span :style="{ color: 'primary' }">
            {{ c.label }}
          </span>
        </v-list-item>
      </v-list>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ selectedLocatorChoiceLabel }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "Step7UserFormLocator",
  props: {
    savedUser: Object,
  },
  computed: {
    selectedLocatorChoiceLabel() {
      return this.locatorChoices.find((a) => a.value === this.user.isLocator)
        ?.label;
    },
    locatorChoices() {
      return [
        {
          value: true,
          label: `Yes, make ${this.savedUser.firstName} an 811 locator.`,
        },
        {
          value: false,
          label: `No, don't make ${this.savedUser.firstName} an 811 locator.`,
        },
      ];
    },
  },
  data() {
    return {
      mdiSend,
      user: {
        isLocator: "",
      },
      locatorChoiceSubmitted: false,
    };
  },
  methods: {
    async saveLocatorChoice() {
      await sleep(250);
      this.$emit("step-7-finished", this.user);
      this.locatorChoiceSubmitted = true;
    },
  },
};
</script>
