var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", persistent: "" },
      model: {
        value: _vm.showEditCityworksIntegrationDialog,
        callback: function ($$v) {
          _vm.showEditCityworksIntegrationDialog = $$v
        },
        expression: "showEditCityworksIntegrationDialog",
      },
    },
    [
      _vm.auth.organization.access_cityworks_811_integration ||
      _vm.auth.organization.access_cityworks_ams_integration
        ? _c(
            "v-card",
            [
              _c("validation-observer", {
                ref: "cityworksForm",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ valid }) {
                        return [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "elevation-0",
                              attrs: { dark: "", color: "primary" },
                            },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Edit Cityworks Integration"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "cityworks-integration-dialog-close"
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "API URL",
                                  rules: {
                                    regex:
                                      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                                    required: true,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "auto",
                                              name: "apiUrl",
                                              "background-color": "white",
                                              label: "API URL",
                                              "error-messages": errors,
                                              success: valid,
                                              hint: "Example: https://myCity.com/cityworks/Services/",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.$emit(
                                                  "cityworks-user-group-info-changed",
                                                  _vm.cityworksIntegrationInfo
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.cityworksIntegrationInfo
                                                  .api_url,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.cityworksIntegrationInfo,
                                                  "api_url",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cityworksIntegrationInfo.api_url",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("BadRequestErrorDialog", {
                                attrs: {
                                  showBadRequestErrorDialog: Boolean(
                                    _vm.badRequestError
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "d-flex justify-end pa-5" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { disabled: !valid, color: "primary" },
                                  on: { click: _vm.saveCityworksSettings },
                                },
                                [_vm._v(" Save ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2351127393
                ),
              }),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "elevation-0",
                  attrs: { dark: "", color: "primary" },
                },
                [
                  _c("v-toolbar-title", [_vm._v("Cityworks Integration")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cityworks-integration-dialog-close")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", { staticClass: "py-5" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.showChat()
                      },
                    },
                  },
                  [_vm._v("Contact sales")]
                ),
                _vm._v(" to get licensed for this integration. "),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }