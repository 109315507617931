import { render, staticRenderFns } from "./RepeatUserNameForm.vue?vue&type=template&id=7ffc6197&"
import script from "./RepeatUserNameForm.vue?vue&type=script&lang=js&"
export * from "./RepeatUserNameForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ffc6197')) {
      api.createRecord('7ffc6197', component.options)
    } else {
      api.reload('7ffc6197', component.options)
    }
    module.hot.accept("./RepeatUserNameForm.vue?vue&type=template&id=7ffc6197&", function () {
      api.rerender('7ffc6197', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organization/users/add-user-dialog/step-1-user-form/RepeatUserNameForm.vue"
export default component.exports