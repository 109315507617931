var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.connectedToEsri,
                  expression: "connectedToEsri",
                },
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " I see your organization is connected to ArcGIS Online/Enterprise. If you enter a username below, the user will be able to use ArcGIS Online/Enterprise to sign in. You can skip this step. "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.connectedToEsri
        ? _c("div", [
            !_vm.esriUserNameSubmitted
              ? _c("div", [
                  _c(
                    "div",
                    [
                      _c("validation-observer", { ref: "esriUserNameForm" }, [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.saveEsriUserName.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "validation-provider",
                              [
                                _c("v-text-field", {
                                  ref: "esriUsernameInput",
                                  attrs: {
                                    outlined: "",
                                    color: "#E5E5E5",
                                    "hide-details": "auto",
                                    name: "serviceName",
                                    "single-line": "",
                                    "background-color": "white",
                                    "full-width": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "mt-n2" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      type: "submit",
                                                      color: "primary",
                                                      disabled:
                                                        !_vm.user.esriUsername,
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.mdiSend) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2592595562
                                  ),
                                  model: {
                                    value: _vm.user.esriUsername,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "esriUsername", $$v)
                                    },
                                    expression: "user.esriUsername",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end my-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.esriUserNameSubmitted = true
                              _vm.$emit("step-2-finished", _vm.user)
                            },
                          },
                        },
                        [_vm._v(" Skip ")]
                      ),
                    ],
                    1
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end my-5" },
                  [
                    _c(
                      "v-list",
                      {
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: "",
                        },
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.user.esriUsername || "Skipped") +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }