<template>
  <v-dialog :value="showEditDepartmentDialog" max-width="600px" persistent>
    <validation-observer
      ref="updateDepartmentForm"
      v-slot="{ valid: isFormValid }"
    >
      <v-card style="background-color: #f1f2f1" class="py-0">
        <v-toolbar dark color="primary" class="elevation-0">
          <v-toolbar-title>Edit Department</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('edit-departmeent-dialog-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          :style="{
            'background-color': '#f1f2f1',
            'overflow-y': 'auto',
            height: '65vh',
            'min-height': '65vh',
          }"
          ref="cardText"
          class="py-1"
        >
          <form @submit.prevent ref="cardInnerText">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Department Name"
              rules="required"
            >
              <v-text-field
                v-model="name"
                label="Department Name"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="departmentName"
                :full-width="false"
              />
            </validation-provider>
          </form>

          <section class="py-2">
            <section ref="tabs">
              <v-tabs
                v-model="tab"
                centered
                background-color="#f1f2f1"
                class="pb-2"
              >
                <v-tab color="primary">Users</v-tab>
                <v-tab color="primary">Maps</v-tab>
              </v-tabs>
            </section>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text class="pa-0">
                    <DepartmentUsersTable
                      v-model="newUsersInDepartment"
                      :departmentId="departmentId"
                      :height="`${tableHeight}px`"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text class="pa-0">
                    <MapDepartmentsTable
                      v-model="newMapsInDepartment"
                      :departmentId="departmentId"
                      :height="`${tableHeight}px`"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </section>

          <ConfirmUpdateAllLayerDepartmentsDialog
            @close="showConfirmUpdateAllLayerDepartmentsDialog = false"
            @confirm="
              showConfirmUpdateAllLayerDepartmentsDialog = false;
              updateDepartment();
            "
            :showConfirmUpdateAllLayerDepartmentsDialog="
              showConfirmUpdateAllLayerDepartmentsDialog
            "
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-end px-5 py-3">
          <v-btn
            color="primary"
            :disabled="!isFormValid"
            @click="tryUpdateDepartment"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";
import DepartmentUsersTable from "@/components/organization/departments/shared/DepartmentUsersTable";
import MapDepartmentsTable from "@/components/organization/departments/shared/MapDepartmentsTable";
import ConfirmUpdateAllLayerDepartmentsDialog from "@/components/organization/departments/shared/ConfirmUpdateAllLayerDepartmentsDialog";
import { isEqual } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "EditDepartmentDialog",
  props: { showEditDepartmentDialog: Boolean, departmentId: String },
  components: {
    DepartmentUsersTable,
    MapDepartmentsTable,
    ConfirmUpdateAllLayerDepartmentsDialog,
  },
  computed: {
    departmentMapsChanged() {
      const { mapsInDepartment, newMapsInDepartment } = this;
      const sortedMapsInDepartment = mapsInDepartment.sort((a, b) =>
        a.department_id.localeCompare(b.department_id)
      );
      const sortedNewNapsInDepartment = newMapsInDepartment.sort((a, b) =>
        a.department_id.localeCompare(b.department_id)
      );
      return !isEqual(sortedMapsInDepartment, sortedNewNapsInDepartment);
    },
  },
  data() {
    return {
      name: "",
      users: [],
      maps: [],
      usersInDepartment: [],
      newUsersInDepartment: [],
      mapsInDepartment: [],
      newMapsInDepartment: [],
      tab: null,
      showConfirmUpdateAllLayerDepartmentsDialog: false,
      tableHeight: 0,
    };
  },
  mounted() {
    this.getTableHeight();
  },
  beforeDestroy() {
    this.$options.mutationObserver?.disconnect();
    this.$options.resizeObserver?.disconnect();
  },
  methods: {
    getTableHeight() {
      this.$options.mutationObserver = new MutationObserver(() => {
        this.$options.resizeObserver = new ResizeObserver(() => {
          this.tableHeight =
            this.$refs.cardText?.clientHeight -
            this.$refs.cardInnerText?.clientHeight -
            this.$refs.tabs?.clientHeight -
            30;
        });
        if (this.$refs.cardText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardText);
        }

        if (this.$refs.cardInnerText instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.cardInnerText);
        }

        if (this.$refs.tabs instanceof HTMLElement) {
          this.$options.resizeObserver.observe(this.$refs.tabs);
        }
      });
      const config = { attributes: true, childList: true, subtree: true };
      this.$options.mutationObserver.observe(document.body, config);
    },
    async tryUpdateDepartment() {
      if (this.departmentMapsChanged) {
        this.showConfirmUpdateAllLayerDepartmentsDialog = true;
        return;
      }
      await this.updateDepartment();
    },
    async updateDepartment() {
      const success = await this.$refs.updateDepartmentForm.validate();
      if (!success) {
        return;
      }
      const {
        name,
        departmentId,
        usersInDepartment,
        newUsersInDepartment,
        mapsInDepartment,
        newMapsInDepartment,
      } = this;
      const auth = JSON.parse(localStorage.getItem("auth"));
      const { user_group_id: userGroupId } = auth;
      await axios.put(`${APIURL}/departments/${departmentId}`, {
        user_group_id: userGroupId,
        name,
      });
      await axios.delete(`${APIURL}/user_departments/batch`, {
        data: {
          user_departments: usersInDepartment,
        },
      });

      await axios.post(`${APIURL}/user_departments/batch`, {
        user_departments: newUsersInDepartment,
      });

      await axios.delete(`${APIURL}/department_maps/batch`, {
        data: {
          department_maps: mapsInDepartment,
        },
      });
      await axios.post(`${APIURL}/department_maps/batch`, {
        department_maps: newMapsInDepartment,
      });

      const mapsIdsInDepartment = mapsInDepartment.map((m) => m.map_id);
      const mapServicesInMapsIdsInDepartment = await Promise.all(
        mapsIdsInDepartment.map(async (mapId) => {
          const {
            data: { results },
          } = await axios.get(`${APIURL}/map_services/inmap`, {
            params: {
              map_id: mapId,
            },
          });
          return results;
        })
      );
      const flattenedMapServicesInMapsIdsInDepartment =
        mapServicesInMapsIdsInDepartment.flat();
      const mapServicesInDepartment =
        flattenedMapServicesInMapsIdsInDepartment.map((m) => {
          const { map_service_id: mapServiceId } = m;
          return { map_service_id: mapServiceId, department_id: departmentId };
        });
      await axios.delete(`${APIURL}/department_map_services/batch`, {
        data: {
          department_map_services: mapServicesInDepartment,
        },
      });

      const newMapsIdsInDepartment = mapsInDepartment.map((m) => m.map_id);
      const newMapServicesInMapsIdsInDepartment = await Promise.all(
        newMapsIdsInDepartment.map(async (mapId) => {
          const {
            data: { results },
          } = await axios.get(`${APIURL}/map_services/inmap`, {
            params: {
              map_id: mapId,
            },
          });
          return results;
        })
      );
      const flattenedNewMapServicesInMapsIdsInDepartment =
        newMapServicesInMapsIdsInDepartment.flat();
      const newMapServicesInDepartment =
        flattenedNewMapServicesInMapsIdsInDepartment.map((m) => {
          const { map_service_id: mapServiceId } = m;
          return { map_service_id: mapServiceId, department_id: departmentId };
        });
      await axios.post(`${APIURL}/department_map_services/batch`, {
        department_map_services: newMapServicesInDepartment,
      });
      this.$emit("saved");
    },
    async getUsersInDepartment(departmentId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_departments/${departmentId}`);
      this.usersInDepartment = results;
      this.newUsersInDepartment = cloneDeep(this.usersInDepartment);
    },
    async getMapsInDepartment(departmentId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/department_maps/${departmentId}`);
      this.mapsInDepartment = results;
      this.newMapsInDepartment = cloneDeep(this.mapsInDepartment);
    },
    async getDepartment(departmentId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments/${departmentId}`);
      const { name } = results;
      this.name = name;
    },
  },
  watch: {
    departmentId: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.getDepartment(val);
          this.getUsersInDepartment(val);
          this.getMapsInDepartment(val);
        }
      },
    },
  },
};
</script>
