<template>
  <div class="my-3" style="width: 750px; max-width: 750px">
    <div class="d-flex align-center justify-space-between mb-1">
      <div class="caption">
        <v-icon size="16px">
          {{ mdiAccountGroup }}
        </v-icon>
        Departments
      </div>
      <div>
        <v-btn
          text
          color="primary"
          class="px-0 mx-0"
          @click="showAddDepartmentDialog = true"
        >
          <v-icon class="mr-1" small>
            {{ mdiPlus }}
          </v-icon>
          Department
        </v-btn>
      </div>
    </div>

    <v-card width="750px" max-width="750px">
      <v-card-text class="ma-0 pa-0">
        <template v-if="departments.length > 0">
          <div
            v-for="d of departments"
            :key="d.department_id"
            class="d-flex justify-space-between align-center px-5 py-3"
          >
            <section class="flex-grow-1 font-weight-medium">
              {{ d.name }}
            </section>

            <section>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>
                      {{ mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list class="py-0">
                  <v-list-item
                    @click="
                      selectedDepartmentId = d.department_id;
                      showEditDepartmentDialog = true;
                    "
                    class="py-1"
                  >
                    <v-list-item-action-text class="d-flex gap align-center">
                      <div>
                        <v-icon color="primary" class="mr-1">
                          {{ mdiPencil }}
                        </v-icon>
                      </div>
                      <div style="color: #286054" class="overflowText">
                        Edit
                      </div>
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item
                    @click="deleteDepartment(d.department_id)"
                    class="py-1"
                  >
                    <v-list-item-action-text class="d-flex gap align-center">
                      <div>
                        <v-icon color="primary" class="mr-1">
                          {{ mdiDelete }}
                        </v-icon>
                      </div>
                      <div style="color: #286054" class="overflowText">
                        Delete
                      </div>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>
              </v-menu>
            </section>
          </div>
        </template>
        <div class="px-5 py-3 text-center" v-else>
          No departments have been added
        </div>
      </v-card-text>
    </v-card>

    <AddDepartmentDialog
      v-if="showAddDepartmentDialog"
      :showAddDepartmentDialog="showAddDepartmentDialog"
      @add-departmeent-dialog-close="showAddDepartmentDialog = false"
      @saved="
        showAddDepartmentDialog = false;
        getDepartments();
      "
    />

    <EditDepartmentDialog
      v-if="showEditDepartmentDialog"
      :showEditDepartmentDialog="showEditDepartmentDialog"
      :departmentId="selectedDepartmentId"
      @edit-departmeent-dialog-close="showEditDepartmentDialog = false"
      @saved="
        showEditDepartmentDialog = false;
        getDepartments();
      "
    />
  </div>
</template>

<script>
import {
  mdiAccountGroup,
  mdiPlus,
  mdiPencil,
  mdiDotsVertical,
  mdiDelete,
} from "@mdi/js";
import AddDepartmentDialog from "@/components/organization/departments/AddDepartmentDialog";
import EditDepartmentDialog from "@/components/organization/departments/EditDepartmentDialog";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Departments",
  data() {
    return {
      mdiAccountGroup,
      mdiPlus,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      showAddDepartmentDialog: false,
      showEditDepartmentDialog: false,
      selectedDepartmentId: undefined,
      departments: [],
    };
  },
  components: { AddDepartmentDialog, EditDepartmentDialog },
  methods: {
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results.sort((a, b) => a.name.localeCompare(b.name));
    },
    async deleteDepartment(departmentId) {
      await axios.delete(`${APIURL}/departments/${departmentId}`);
      await this.getDepartments();
    },
  },
  beforeMount() {
    this.getDepartments();
  },
};
</script>

<style scoped>
.overflowText {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
}
</style>
