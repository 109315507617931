<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        Cell phone so {{ savedUser.firstName }} can receive text notifications?
        By entering a phone number, you are opting-in to receive text messages.
        You can skip this step.
      </v-alert>
    </v-expand-transition>

    <div v-if="!cellPhoneSubmitted">
      <div>
        <validation-observer ref="cellPhoneForm">
          <form @submit.prevent="saveCellPhone">
            <validation-provider
              :rules="{
                regex:
                  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              }"
            >
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model="user.cellPhone"
                hide-details="auto"
                name="cellPhone"
                single-line
                background-color="white"
                full-width
                ref="cellPhone"
                v-mask="'##########'"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="primary"
                      :disabled="!user.cellPhone"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          text
          @click="
            cellPhoneSubmitted = true;
            $emit('step-4-finished', user);
          "
          color="primary"
        >
          Skip
        </v-btn>
      </div>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ user.cellPhone || "Skipped" }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "Step4UserFormCellPhone",
  props: {
    savedUser: Object,
  },
  data() {
    return {
      mdiSend,
      user: {
        cellPhone: "",
      },
      cellPhoneSubmitted: false,
    };
  },
  methods: {
    async saveCellPhone() {
      const success = await this.$refs.cellPhoneForm.validate();
      if (!success) {
        return;
      }
      await sleep(250);
      this.$emit("step-4-finished", this.user);
      this.cellPhoneSubmitted = true;
    },
  },
  async beforeMount() {
    await this.$nextTick();
    this.$refs.cellPhone.$refs.input.focus();
  },
};
</script>
