<template>
  <div class="my-3">
    <div
      class="d-flex align-center justify-space-between mb-1"
      style="width: 750px; max-width: 750px"
    >
      <div class="caption">
        <v-icon size="16px">
          {{ mdiPuzzle }}
        </v-icon>
        Integrations
      </div>
      <div>
        <v-btn
          text
          color="primary"
          class="px-0 mx-0"
          @click="showAddIntegrationDialog = true"
          id="addIntegrationButton"
        >
          <v-icon class="mr-1" small>
            {{ mdiPlus }}
          </v-icon>
          Integration
        </v-btn>
      </div>
    </div>

    <v-card width="750px" max-width="750px">
      <v-card-text class="ma-0 pa-0">
        <div
          class="d-flex align-center justify-space-between px-5 py-3 cursor-pointer"
          v-if="hasCityworksIntegration"
          @click="showCityworksIntegrationDialog = true"
        >
          <section class="flex-grow-1 font-weight-medium">Cityworks</section>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  width="30px"
                  min-width="30px"
                >
                  <v-icon color="primary">{{ mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list class="py-0">
                <v-list-item
                  class="py-1"
                  @click="showEditCityworksIntegrationDialog = true"
                >
                  <v-list-item-action-text class="d-flex gap align-center">
                    <div class="overflow-text">Edit Cityworks API URL</div>
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item
                  class="py-1"
                  @click="showCityworksIntegrationDialog = true"
                >
                  <v-list-item-action-text class="d-flex gap align-center">
                    <div class="overflow-text">Cityworks 811 Integration</div>
                  </v-list-item-action-text>
                </v-list-item>

                <v-list-item class="py-1" @click="removeCityworksIntegration">
                  <v-list-item-action-text class="d-flex gap align-center">
                    <div style="color: red" class="overflow-text">
                      Remove Integration
                    </div>
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <v-divider
          v-if="
            [
              ARC_GIS_CONNECTION_TYPES.AGOL,
              ARC_GIS_CONNECTION_TYPES.PORTAL,
            ].includes(auth.organization.gis_connection_type)
          "
        ></v-divider>

        <div
          v-if="
            hasCityworksIntegration ||
            hasValidCityworksToken ||
            [
              ARC_GIS_CONNECTION_TYPES.AGOL,
              ARC_GIS_CONNECTION_TYPES.PORTAL,
            ].includes(auth.organization.gis_connection_type)
          "
        >
          <div
            class="d-flex align-center justify-space-between px-5 py-3"
            v-if="
              [
                ARC_GIS_CONNECTION_TYPES.AGOL,
                ARC_GIS_CONNECTION_TYPES.PORTAL,
              ].includes(auth.organization.gis_connection_type)
            "
          >
            <section class="flex-grow-1 font-weight-medium">
              Connected to {{ getArcGisConnectionType(auth.organization) }}
            </section>

            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    width="30px"
                    min-width="30px"
                  >
                    <v-icon color="primary">{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list class="py-0">
                  <v-list-item @click="disconnectArcGisConnection" class="py-1">
                    <v-list-item-action-text class="d-flex gap align-center">
                      <div>
                        <v-icon color="red">
                          {{ mdiPuzzleRemove }}
                        </v-icon>
                      </div>
                      <div style="color: red" class="overflow-text">
                        Remove Integration
                      </div>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <div v-else class="px-5 py-3 text-center">
          No Integrations Have Been Added
        </div>
      </v-card-text>
    </v-card>

    <AddIntegrationDialog
      v-if="showAddIntegrationDialog"
      :showAddIntegrationDialog="showAddIntegrationDialog"
      :userGroupIntegrationSettings="userGroupIntegrationSettings"
      @add-integration-dialog-close="showAddIntegrationDialog = false"
      @connection-success="
        showAddIntegrationDialog = false;
        updateConnectionData();
      "
      @cityworks-settings-saved="
        showAddIntegrationDialog = false;
        updateConnectionData();
        getCityworksUserGroupInfo();
      "
    />

    <CityworksIntegrationDialog
      v-if="showCityworksIntegrationDialog"
      :showCityworksIntegrationDialog="showCityworksIntegrationDialog"
      @cityworks-integration-dialog-close="
        showCityworksIntegrationDialog = false
      "
      @cityworks-settings-saved="$router.go(0)"
    />

    <EditCityworksIntegrationDialog
      v-if="showEditCityworksIntegrationDialog"
      :showEditCityworksIntegrationDialog="showEditCityworksIntegrationDialog"
      @cityworks-integration-dialog-close="
        showEditCityworksIntegrationDialog = false
      "
      @cityworks-settings-saved="
        showEditCityworksIntegrationDialog = false;
        $emit('cityworks-settings-saved');
      "
    />
  </div>
</template>

<script>
import {
  mdiPuzzle,
  mdiPlus,
  mdiPuzzleRemove,
  mdiMenuRight,
  mdiDotsVertical,
} from "@mdi/js";
import AddIntegrationDialog from "@/components/organization/integrations/AddIntegrationDialog";
import CityworksIntegrationDialog from "@/components/organization/integrations/shared/CityworksIntegrationDialog";
import EditCityworksIntegrationDialog from "@/components/organization/integrations/shared/EditCityworksIntegrationDialog";
import axios from "axios";
import authObjectMixin from "@/mixins/authObjectMixin";

const APIURL = process.env.VUE_APP_API_URL;
const ARC_GIS_CONNECTION_TYPES = {
  NO_GIS_CONNECTION: "no_gis_connection",
  AGOL: "agol",
  PORTAL: "portal",
};

export default {
  name: "Integrations",
  components: {
    AddIntegrationDialog,
    EditCityworksIntegrationDialog,
    CityworksIntegrationDialog,
  },
  props: {
    userGroup: Object,
  },
  computed: {
    hasCityworksIntegration() {
      return this.cityworksIntegrationInfo?.api_url;
    },
  },
  data() {
    return {
      ARC_GIS_CONNECTION_TYPES,
      mdiPuzzle,
      mdiPlus,
      mdiMenuRight,
      mdiDotsVertical,
      mdiPuzzleRemove,
      showAddIntegrationDialog: false,
      showEditCityworksIntegrationDialog: false,
      showCityworksIntegrationDialog: false,
      cityworksUserGroupInfo: {},
      hasValidCityworksToken: false,
      userGroupIntegrationSettings: {},
      auth: {},
      cityworksIntegrationInfo: {},
    };
  },
  mixins: [authObjectMixin],
  methods: {
    async removeCityworksIntegration() {
      await axios.delete(`${APIURL}/cityworks/user_group_info`);
      await this.getCityworksUserGroupInfo();
    },
    getArcGisConnectionType(userGroupIntegrationSettings) {
      if (
        userGroupIntegrationSettings.gis_connection_type ===
        ARC_GIS_CONNECTION_TYPES.AGOL
      ) {
        return "ArcGIS Online";
      } else if (
        userGroupIntegrationSettings.gis_connection_type ===
        ARC_GIS_CONNECTION_TYPES.PORTAL
      ) {
        return "Portal";
      }
    },
    async getCityworksUserGroupInfo() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
      this.cityworksUserGroupInfo = results;
      const {
        data: { results: cityworksIntegrationInfo },
      } = await axios.get(`${APIURL}/cityworks/user_group_info`);
      this.cityworksIntegrationInfo = cityworksIntegrationInfo;
    },
    async validateCityworksToken() {
      try {
        const {
          data: {
            results: { service_request_token: serviceRequestToken },
          },
        } = await axios.get(`${APIURL}/cityworks/locate_request_integration`);
        this.hasValidCityworksToken = Boolean(serviceRequestToken);
      } catch (error) {
        this.hasValidCityworksToken = false;
      }
    },
    async getUserGroupData() {
      const { user_group_id: userGroupId } = this.auth;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_groups/${userGroupId}`);
      this.userGroupIntegrationSettings = results;
    },
    async disconnectArcGisConnection() {
      await axios.put(`${APIURL}/auth/set_GIS_connection`, {
        gis_connection_type: "no_gis_connection",
      });
      localStorage.removeItem("esri_token");
      await this.updateConnectionData();
    },
    async updateConnectionData() {
      await this.updateAuthObject();
      this.auth = JSON.parse(localStorage.getItem("auth"));
      await this.getUserGroupData();
      await this.validateCityworksToken();
      this.$router.go(0);
    },
  },
  async beforeMount() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    await this.getUserGroupData();
    await this.getCityworksUserGroupInfo();
    await this.validateCityworksToken();
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}

.overflow-text {
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 500;
}
</style>
