var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" Email for " + _vm._s(_vm.savedUser.firstName) + "? ")]
          ),
        ],
        1
      ),
      !_vm.emailSubmitted
        ? _c("div", [
            _c(
              "div",
              [
                _c("validation-observer", { ref: "emailForm" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveEmail.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "validation-provider",
                        { attrs: { rules: { required: true, email: true } } },
                        [
                          _c("v-text-field", {
                            ref: "emailInput",
                            attrs: {
                              outlined: "",
                              color: "#E5E5E5",
                              "hide-details": "auto",
                              name: "email",
                              "single-line": "",
                              "background-color": "white",
                              "full-width": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "mt-n2" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                type: "submit",
                                                color: "primary",
                                                disabled: !_vm.user.email,
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.mdiSend) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1864359165
                            ),
                            model: {
                              value: _vm.user.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "email", $$v)
                              },
                              expression: "user.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "d-flex justify-end my-5" },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    outlined: "",
                    rounded: "",
                    width: "fit-content",
                    dense: "",
                  },
                },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.user.email) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }