<template>
  <v-card>
    <v-card-text>
      <validation-observer ref="portalUrlForm">
        <form>
          <v-row>
            <v-col cols="12" class="py-0 my-0" id="enterpriseURL">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Enterprise URL"
                :rules="{
                  required: true,
                  regex:
                    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
                }"
              >
                <div class="d-flex align-center gap">
                  <div class="caption">Enterprise URL</div>
                  <v-btn
                    fab
                    width="15px"
                    height="15px"
                    color="primary"
                    class="d-flex justify-center align-center"
                    @click="showPortalUrlDescDialog = true"
                    dark
                  >
                    <v-icon x-small>
                      {{ mdiHelp }}
                    </v-icon>
                  </v-btn>
                </div>
                <v-text-field
                  v-model.trim="portal.portalUrl"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  class="py-0 my-0"
                  @input="$emit('portal-updated', portal)"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" class="py-0 my-0 mt-3" id="addID">
              <validation-provider
                v-slot="{ errors, valid }"
                name="APP ID"
                rules="required"
              >
                <div class="d-flex align-center gap">
                  <div class="caption">APP ID</div>
                  <v-btn
                    fab
                    width="15px"
                    height="15px"
                    color="primary"
                    class="d-flex justify-center align-center"
                    @click="showAppIdDescDialog = true"
                    dark
                  >
                    <v-icon x-small>
                      {{ mdiHelp }}
                    </v-icon>
                  </v-btn>
                </div>
                <v-text-field
                  v-model.trim="portal.appId"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  class="py-0 my-0"
                  @input="$emit('portal-updated', portal)"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </form>
      </validation-observer>

      <PortalUrlDescDialog
        :showPortalUrlDescDialog="showPortalUrlDescDialog"
        v-if="showPortalUrlDescDialog"
        @portal-url-desc-dialog-close="showPortalUrlDescDialog = false"
      />

      <AppIdDescDialog
        :showAppIdDescDialog="showAppIdDescDialog"
        v-if="showAppIdDescDialog"
        @portal-app-id-desc-dialog-close="showAppIdDescDialog = false"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiHelp } from "@mdi/js";
import axios from "axios";
import PortalUrlDescDialog from "@/components/organization/integrations/shared/gis-connection-dialog/portal-connection/PortalUrlDescDialog";
import AppIdDescDialog from "@/components/organization/integrations/shared/gis-connection-dialog/portal-connection/AppIdDescDialog";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PortalConnection",
  components: {
    PortalUrlDescDialog,
    AppIdDescDialog,
  },
  data() {
    return {
      mdiHelp,
      portal: {
        portalUrl: "https://",
        appId: "",
      },
      showAppIdDescDialog: false,
      showPortalUrlDescDialog: false,
    };
  },
  methods: {
    async getUserGroupData() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_groups/${userGroupId}`);
      const { esri_url: portalUrl, esri_client_id: appId } = results;
      this.portal = {
        portalUrl,
        appId,
      };
    },
  },
  beforeMount() {
    this.getUserGroupData();
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
