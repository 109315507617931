<template>
  <v-dialog :value="showDepartmentAccessDialog" max-width="600px">
    <v-card style="background-color: #f1f2f1">
      <v-toolbar dark color="primary" ref="toolbar">
        <v-toolbar-title>Department Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('department-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        :style="{
          'background-color': '#f1f2f1',
          'overflow-y': 'auto',
          height: '65vh',
        }"
        class="py-2"
      >
        <p><b>The user is a member of the following departments:</b></p>

        <v-simple-table fixed-header height="calc(65vh - 70px)">
          <thead>
            <tr>
              <th>
                <v-simple-checkbox
                  :value="
                    newDepartmentsUsersHaveAccessTo.length ===
                    departments.length
                  "
                  @input="toggleAllUserDepartmentAccess()"
                  :disabled="!canManageUsers"
                ></v-simple-checkbox>
              </th>
              <th>Departments</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d of departments" :key="d.department_id">
              <td>
                <v-simple-checkbox
                  :value="userHasDepartmentAccess(d.department_id)"
                  @input="toggleUserDepartmentAccess(d.department_id)"
                  :disabled="!canManageUsers"
                ></v-simple-checkbox>
              </td>
              <td>{{ d.name }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5">
        <v-btn color="primary" @click="updateDepartmentsUserHasAccessTo">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import contentHeightMixin from "@/mixins/contentHeightMixin";
import axios from "axios";
import { isEqual } from "lodash";
import { cloneDeep } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DepartmentAccessDialog",
  mixins: [contentHeightMixin, permissionsMixin],
  props: {
    showDepartmentAccessDialog: Boolean,
    selectedUser: Object,
  },
  data() {
    return {
      departments: [],
      departmentsUsersHaveAccessTo: [],
      newDepartmentsUsersHaveAccessTo: [],
    };
  },
  methods: {
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    async getDepartmentsUserHaveAccessTo(userId) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_departments/user/${userId}`);
      this.departmentsUsersHaveAccessTo = results;
      this.newDepartmentsUsersHaveAccessTo = cloneDeep(
        this.departmentsUsersHaveAccessTo
      );
    },
    userHasDepartmentAccess(departmentId) {
      return Boolean(
        this.newDepartmentsUsersHaveAccessTo.find(
          (d) => d.department_id === departmentId
        )
      );
    },
    toggleUserDepartmentAccess(departmentId) {
      const index = this.newDepartmentsUsersHaveAccessTo.findIndex(
        (d) => d.department_id === departmentId
      );
      if (index >= 0) {
        this.newDepartmentsUsersHaveAccessTo.splice(index, 1);
      } else {
        const userDepartment = {
          user_id: this.selectedUser.user_id,
          department_id: departmentId,
        };
        this.newDepartmentsUsersHaveAccessTo.push(userDepartment);
      }
    },
    toggleAllUserDepartmentAccess() {
      if (this.newDepartmentsUsersHaveAccessTo.length === 0) {
        this.newDepartmentsUsersHaveAccessTo = this.departments.map((d) => {
          const { department_id: departmentId } = d;
          return {
            user_id: this.selectedUser.user_id,
            department_id: departmentId,
          };
        });
      } else {
        this.newDepartmentsUsersHaveAccessTo = [];
      }
    },
    async updateDepartmentsUserHasAccessTo() {
      const { newDepartmentsUsersHaveAccessTo, departmentsUsersHaveAccessTo } =
        this;
      const userDepartmentsToInsert = newDepartmentsUsersHaveAccessTo.filter(
        (newUserDepartment) => {
          return !departmentsUsersHaveAccessTo.find((oldUserDepartment) => {
            return isEqual(oldUserDepartment, newUserDepartment);
          });
        }
      );
      await axios.post(`${APIURL}/user_departments/batch`, {
        user_departments: userDepartmentsToInsert,
      });
      const userDepartmentsToDelete = departmentsUsersHaveAccessTo.filter(
        (oldUserDepartment) => {
          return !newDepartmentsUsersHaveAccessTo.find((newUserDepartment) => {
            return isEqual(oldUserDepartment, newUserDepartment);
          });
        }
      );
      await axios.delete(`${APIURL}/user_departments/batch`, {
        data: {
          user_departments: userDepartmentsToDelete,
        },
      });
      this.$emit("saved");
    },
  },
  beforeMount() {
    this.getDepartments();
  },
  watch: {
    "selectedUser.user_id": {
      immediate: true,
      handler(userId) {
        if (userId) {
          this.getDepartmentsUserHaveAccessTo(userId);
        }
      },
    },
    departments: {
      deep: true,
      immediate: true,
      handler() {
        if (this.selectedUser.user_id) {
          this.getDepartmentsUserHaveAccessTo(this.selectedUser.user_id);
        }
      },
    },
  },
};
</script>
