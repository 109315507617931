var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "p",
        { staticClass: "caption mb-2" },
        [
          _c("v-icon", { attrs: { size: "16px" } }, [
            _vm._v(" " + _vm._s(_vm.mdiChartDonut) + " "),
          ]),
          _vm._v(" Licensing Plan and Usage "),
        ],
        1
      ),
      _vm.licensing.user_group.access_811_tms_app ||
      _vm.licensing.user_group.access_field_reports_app
        ? _c(
            "v-card",
            { attrs: { width: "750px", "max-width": "750px" } },
            [
              _vm.licensing.user_group.access_811_tms_app
                ? _c(
                    "v-card-text",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-center",
                          staticStyle: { "margin-left": "-4px" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/UtiliSyncLogoTMS.svg"),
                              height: "75px",
                              width: "auto",
                            },
                          }),
                        ]
                      ),
                      _c("table", { staticStyle: { width: "70%" } }, [
                        _c("col", { attrs: { width: "35%" } }),
                        _c("col", { attrs: { width: "25%" } }),
                        _c("col", { attrs: { width: "20%" } }),
                        _c("col", { attrs: { width: "20%" } }),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "16px" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.mdiCalendarRefresh) + " "
                                    ),
                                  ]
                                ),
                                _vm._v("Renewal Date"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              {
                                style: _vm.renewalOverdue
                                  ? "color:#ff6060"
                                  : "color:#286054",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateUTC")(
                                      _vm.licensing.user_group.plan_renewal
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "16px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.mdiCreditCardOutline) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._v("Payment Method"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              { staticStyle: { color: "#286054" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.licensing.user_group.payment_type
                                      ? _vm.licensing.user_group.payment_type
                                      : "Not Specified"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { margin: "0 2px 0 -2px" },
                                    attrs: { size: "20px" },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.mdiAutoFix) + " ")]
                                ),
                                _vm._v("Route Optimization"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              { staticStyle: { color: "#286054" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.licensing.user_group
                                      .route_optimization_811
                                      ? "Included"
                                      : "Not Included"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { margin: "0 2px 0 -2px" },
                                    attrs: { size: "20px" },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.mdiCity) + " ")]
                                ),
                                _vm._v("Cityworks Integration"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              { staticStyle: { color: "#286054" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.licensing.user_group
                                      .access_cityworks_811_integration
                                      ? "Included"
                                      : "Not Included"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "16px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.mdiMapMarkerMultiple) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._v("Tickets Received"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              {
                                style:
                                  _vm.percentageUsedTickets > 100
                                    ? "color:#ff6060"
                                    : "color:#286054",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.numTickets) + " Received "
                                ),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "px-0" }, [
                            _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                _c("v-progress-linear", {
                                  attrs: {
                                    value: _vm.percentageUsedTickets,
                                    height: "14",
                                    color:
                                      _vm.percentageUsedTickets > 100
                                        ? "#ff6060"
                                        : "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              {
                                style:
                                  _vm.percentageUsedTickets > 100
                                    ? "color:#ff6060"
                                    : "color:#286054",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.licensing.user_group
                                        .number_of_811_tickets
                                    ) +
                                    " Total "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.licensing.user_group.access_field_reports_app
                ? _c("hr", {
                    staticStyle: { "border-width": "0.25px" },
                    attrs: { color: "#E0E0E0" },
                  })
                : _vm._e(),
              _vm.licensing.user_group.access_field_reports_app
                ? _c(
                    "v-card-text",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/UtiliSyncLogoInspect.svg"),
                            height: "50px",
                            width: "auto",
                          },
                        }),
                      ]),
                      _c("table", { staticStyle: { width: "70%" } }, [
                        _c("col", { attrs: { width: "35%" } }),
                        _c("col", { attrs: { width: "25%" } }),
                        _c("col", { attrs: { width: "20%" } }),
                        _c("col", { attrs: { width: "20%" } }),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "16px" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.mdiInformation) + " "
                                    ),
                                  ]
                                ),
                                _vm._v("Plan Type"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              { staticStyle: { color: "#286054" } },
                              [_vm._v(_vm._s(_vm.inspectPlanType))]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "16px" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.mdiCalendarRefresh) + " "
                                    ),
                                  ]
                                ),
                                _vm._v("Renewal Date"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              {
                                style: _vm.renewalOverdue
                                  ? "color:#ff6060"
                                  : "color:#286054",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateUTC")(
                                      _vm.licensing.user_group.plan_renewal
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "16px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.mdiCreditCardOutline) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._v("Payment Method"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              { staticStyle: { color: "#286054" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.licensing.user_group.payment_type
                                      ? _vm.licensing.user_group.payment_type
                                      : "Not Specified"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { margin: "0 2px 0 -2px" },
                                    attrs: { size: "20px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.mdiMapMarkerAccount) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._v("Site Contacts"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              { staticStyle: { color: "#286054" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.licensing.user_group
                                      .paying_for_linked_sites
                                      ? "Included"
                                      : "Not Included"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { margin: "0 2px 0 -2px" },
                                    attrs: { size: "20px" },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.mdiCity) + " ")]
                                ),
                                _vm._v("Cityworks Integration"),
                              ],
                              1
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "strong",
                              { staticStyle: { color: "#286054" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.licensing.user_group
                                      .access_cityworks_ams_integration
                                      ? "Included"
                                      : "Not Included"
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm.licensing.user_group.field_reports_plan_type ==
                        "organization"
                          ? _c("tr", [
                              _c("td", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { size: "16px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.mdiFileDocumentMultipleOutline
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v("Form Submissions"),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedForms > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.numFormSubmissions) +
                                        " Active "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "px-0" }, [
                                _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        value: _vm.percentageUsedForms,
                                        height: "14",
                                        color:
                                          _vm.percentageUsedForms > 100
                                            ? "#ff6060"
                                            : "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedForms > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.licensing.user_group
                                            .number_of_form_submissions
                                        ) +
                                        " Total "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.licensing.user_group.field_reports_plan_type ==
                        "sites"
                          ? _c("tr", [
                              _c("td", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { size: "16px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.mdiMapMarkerMultiple) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v("Sites Per Month"),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedActiveSites > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.numSites) + " Active "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "px-0" }, [
                                _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        value: _vm.percentageUsedActiveSites,
                                        height: "14",
                                        color:
                                          _vm.percentageUsedActiveSites > 100
                                            ? "#ff6060"
                                            : "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedActiveSites > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.licensing.user_group
                                            .number_of_active_sites
                                        ) +
                                        " Total "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.licensing.user_group.field_reports_plan_type ==
                          "organization" ||
                        _vm.licensing.user_group.field_reports_plan_type ==
                          "power_users"
                          ? _c("tr", [
                              _c("td", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { size: "16px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.mdiAccountMultiple) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v("View Only Users"),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedViewOnly > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.numViewOnlyUsers) +
                                        " Active "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "px-0" }, [
                                _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        value: _vm.percentageUsedViewOnly,
                                        height: "14",
                                        color:
                                          _vm.percentageUsedViewOnly > 100
                                            ? "#ff6060"
                                            : "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedViewOnly > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.licensing.user_group
                                            .number_of_view_only_users
                                        ) +
                                        " Total "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.licensing.user_group.field_reports_plan_type ==
                        "power_users"
                          ? _c("tr", [
                              _c("td", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { size: "16px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.mdiAccountMultiple) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v("Power Users"),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedPower > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.numPowerUsers) +
                                        " Active "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "px-0" }, [
                                _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        value: _vm.percentageUsedPower,
                                        height: "14",
                                        color:
                                          _vm.percentageUsedPower > 100
                                            ? "#ff6060"
                                            : "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedPower > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.licensing.user_group
                                            .number_of_power_users
                                        ) +
                                        " Total "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.licensing.user_group.field_reports_plan_type ==
                        "organization"
                          ? _c("tr", [
                              _c("td", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { size: "16px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.mdiAccountMultiple) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v("Standard Users"),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedStandard > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.numStandardUsers) +
                                        " Active "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "px-0" }, [
                                _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        value: _vm.percentageUsedStandard,
                                        height: "14",
                                        color:
                                          _vm.percentageUsedStandard > 100
                                            ? "#ff6060"
                                            : "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "strong",
                                  {
                                    style:
                                      _vm.percentageUsedStandard > 100
                                        ? "color:#ff6060"
                                        : "color:#286054",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.licensing.user_group
                                            .number_of_standard_users
                                        ) +
                                        " Total "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "v-card",
            [
              !_vm.licensingLoaded
                ? _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center align-center" },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            indeterminate: "",
                            size: "16",
                            color: "primary",
                          },
                        }),
                        _c("div", { staticClass: "ml-2 font-weight-medium" }, [
                          _vm._v(" Loading Licensing Plan and Usage "),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _c("v-card-text", { staticClass: "d-flex justify-center" }, [
                    _c("div", { staticClass: "font-weight-medium" }, [
                      _vm._v("No Licensing Available"),
                    ]),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }