<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        <div>
          Upon reviewing your account, I see you have:
          <ul>
            <li v-if="numViewOnlyUsersAvailable > 0">
              {{ numViewOnlyUsersAvailable }} View-Only Users available
            </li>
            <li
              v-if="
                (userGroup.field_reports_plan_type === 'organization' ||
                  userGroup.field_reports_plan_type === 'sites') &&
                numStandardUsersAvailable > 0
              "
            >
              {{ numStandardUsersAvailable }} Standard Users available
            </li>
            <li
              v-if="
                userGroup.field_reports_plan_type === 'power_users' &&
                numPowerUsersAvailable > 0
              "
            >
              {{ numPowerUsersAvailable }} Power Users available
            </li>
            <li
              v-if="
                numViewOnlyUsersAvailable <= 0 &&
                numPowerUsersAvailable <= 0 &&
                numStandardUsersAvailable <= 0
              "
            >
              No users available.
            </li>
            <li v-if="!userGroup.field_reports_plan_type">
              No licensing set for organization. Reach out to
              <strong
                ><a href="mailto:support@utilisync.com" style="color: white"
                  >UtiliSync Support</a
                ></strong
              >
              to get this set.
            </li>
          </ul>
        </div>
      </v-alert>
    </v-expand-transition>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        <div
          v-if="
            numViewOnlyUsersAvailable <= 0 &&
            numPowerUsersAvailable <= 0 &&
            numStandardUsersAvailable <= 0
          "
        >
          Reach out to
          <strong
            ><a href="mailto:support@utilisync.com" style="color: white"
              >UtiliSync Support</a
            ></strong
          >
          to discuss options for creating this user.
        </div>
        <div v-else>
          What type of user do you want to assign to
          <strong>{{ savedUser.firstName }}</strong> for the Inspect app?
        </div>

        <div class="mt-4">
          <ul>
            <li>
              View-Only Users - Cannot complete forms but can access the app to
              view its information
            </li>
            <li v-if="userGroup.field_reports_plan_type === 'power_users'">
              Power Users - Can create an unlimited number of field reports.
            </li>
            <li
              v-if="
                userGroup.field_reports_plan_type === 'organization' ||
                userGroup.field_reports_plan_type === 'sites'
              "
            >
              Standard Users - Can complete forms, but are limited based on the
              number of form submissions your account is licensed for.
            </li>
          </ul>
        </div>
      </v-alert>
    </v-expand-transition>

    <div v-if="!userTypeChoiceSubmitted" class="d-flex justify-end">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
      >
        <v-list-item
          v-for="(c, i) of filteredUserTypeChoices"
          :key="c.label"
          :style="{
            'border-bottom':
              i !== filteredUserTypeChoices.length - 1
                ? '1px solid primary'
                : '',
            'background-color': 'white',
          }"
          class="my-0 py-0"
          @click="
            userTypeChoice = c.value;
            userTypeChoiceSubmitted = true;
            saveUserTypeChoice();
          "
        >
          <span :style="{ color: 'primary' }">
            {{ c.label }}
          </span>
        </v-list-item>
      </v-list>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ saveUserTypeChoiceLabel }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import axios from "axios";
import { USER_TYPES, USER_TYPE_CHOICES } from "@/constants/userTypes";
import sleep from "@/mixins/sleep";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Step9UserFormUserType",
  props: {
    savedUser: Object,
    userGroup: Object,
  },
  computed: {
    saveUserTypeChoiceLabel() {
      return this.USER_TYPE_CHOICES.find((a) => a.value === this.userTypeChoice)
        ?.label;
    },
    numStandardUsersAvailable() {
      return (
        (this.userGroup.number_of_standard_users ?? 0) -
        this.users.filter((u) => u.is_standard_user && u.is_active).length
      );
    },
    numPowerUsersAvailable() {
      return (
        this.userGroup.number_of_power_users -
        this.users.filter((u) => u.is_power_user && u.is_active).length
      );
    },
    numViewOnlyUsersAvailable() {
      return (
        this.userGroup.number_of_view_only_users -
        this.users.filter((u) => u.is_view_only_user && u.is_active).length
      );
    },
    filteredUserTypeChoices() {
      if (
        this.userGroup.field_reports_plan_type === "power_users" &&
        this.numPowerUsersAvailable > 0
      ) {
        return USER_TYPE_CHOICES.filter(
          (u) =>
            u.value === USER_TYPES.VIEW_ONLY_USER ||
            u.value === USER_TYPES.POWER_USER
        );
      } else if (
        (this.userGroup.field_reports_plan_type === "organization" ||
          this.userGroup.field_reports_plan_type === "sites") &&
        this.numStandardUsersAvailable > 0
      ) {
        return USER_TYPE_CHOICES.filter(
          (u) =>
            u.value === USER_TYPES.VIEW_ONLY_USER ||
            u.value === USER_TYPES.STANDARD_USER
        );
      } else {
        return USER_TYPE_CHOICES.filter(
          (u) => u.value === USER_TYPES.VIEW_ONLY_USER
        );
      }
    },
  },
  data() {
    return {
      mdiSend,
      user: {
        isPowerUser: false,
        isViewOnlyUser: false,
      },
      userTypeChoiceSubmitted: false,
      USER_TYPE_CHOICES,
      userTypeChoice: undefined,
      users: [],
    };
  },
  methods: {
    async saveUserTypeChoice() {
      if (this.userTypeChoice === USER_TYPES.VIEW_ONLY_USER) {
        this.user.isViewOnlyUser = true;
        this.user.isPowerUser = false;
        this.user.isStandardUser = false;
      } else if (this.userTypeChoice === USER_TYPES.STANDARD_USER) {
        this.user.isViewOnlyUser = false;
        this.user.isPowerUser = false;
        this.user.isStandardUser = true;
      } else if (this.userTypeChoice === USER_TYPES.POWER_USER) {
        this.user.isViewOnlyUser = false;
        this.user.isPowerUser = true;
        this.user.isStandardUser = false;
      }
      await sleep(250);
      this.$emit("step-8-finished", this.user);
      this.userTypeChoiceSubmitted = true;
    },
    async getUserGroupUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results;
    },
  },
  beforeMount() {
    this.getUserGroupUsers();
  },
};
</script>
