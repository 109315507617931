var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditUserDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        { staticStyle: { "background-color": "#f1f2f1" } },
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Edit User")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit-user-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "65vh",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _c("validation-observer", { ref: "editUserForm" }, [
                _c(
                  "form",
                  [
                    _c("InformationForm", {
                      attrs: {
                        selectedUser: _vm.selectedUser,
                        userGroup: _vm.userGroup,
                      },
                      on: { input: _vm.onInformationFormSaved },
                    }),
                    _c("PermissionsForm", {
                      attrs: {
                        selectedUser: _vm.selectedUser,
                        userBeingEdited: _vm.user,
                        userGroup: _vm.userGroup,
                      },
                      on: { input: _vm.onPermissionsFormSaved },
                    }),
                    _vm.userGroup.access_field_reports_app ||
                    _vm.userGroup.access_811_tms_app
                      ? _c("NotificationsForm", {
                          attrs: {
                            selectedUser: _vm.selectedUser,
                            inspectAccess:
                              _vm.userGroup.access_field_reports_app,
                            tmsAccess: _vm.userGroup.access_811_tms_app,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end py-5 px-7" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "userSaveButton",
                    color: "primary",
                    disabled: !_vm.canManageUsers,
                  },
                  on: { click: _vm.saveUser },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }