var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "350px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
      model: {
        value: _vm.showCityworksSignInDialog,
        callback: function ($$v) {
          _vm.showCityworksSignInDialog = $$v
        },
        expression: "showCityworksSignInDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Cityworks Sign-in")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cityworks-sign-in-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("validation-observer", { ref: "form" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pt-3" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "px-4", attrs: { cols: "12" } },
                          [_vm._v(" Sign in with your Cityworks credentials. ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Username", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "mdi-account",
                                          label: "Username *",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "username",
                                          disabled:
                                            _vm.loggingIn || _vm.loginSuccess,
                                        },
                                        model: {
                                          value: _vm.cityworksUser.username,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cityworksUser,
                                              "username",
                                              $$v
                                            )
                                          },
                                          expression: "cityworksUser.username",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Password", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "mdi-lock",
                                          label: "Password *",
                                          type: "password",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "password",
                                          disabled:
                                            _vm.loggingIn || _vm.loginSuccess,
                                        },
                                        model: {
                                          value: _vm.cityworksUser.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cityworksUser,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "cityworksUser.password",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.loginFailed
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-center red--text",
                                attrs: { cols: "12" },
                              },
                              [_vm._v(" Invalid username/password ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.loginSuccess
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex justify-center primary--text",
                                attrs: { cols: "12" },
                              },
                              [_vm._v(" You are now signed in. ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  type: "submit",
                                  elevation: "0",
                                  raised: "",
                                  width: "100%",
                                  color: "primary",
                                  depressed: "",
                                  disabled: _vm.loggingIn || _vm.loginSuccess,
                                },
                              },
                              [
                                _vm.loggingIn
                                  ? [_vm._v(" Authenticating ")]
                                  : [
                                      _vm.loginSuccess
                                        ? [_vm._v(" Success! ")]
                                        : [_vm._v(" Sign In ")],
                                    ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.hasEsriToken
                      ? _c("div", { staticClass: "strike" }, [
                          _c("span", [_vm._v("OR")]),
                        ])
                      : _vm._e(),
                    _vm.hasEsriToken
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      type: "submit",
                                      elevation: "0",
                                      raised: "",
                                      width: "100%",
                                      color: "primary",
                                      outlined: "",
                                      disabled:
                                        _vm.loggingIn || _vm.loginSuccess,
                                    },
                                    on: { click: _vm.esriLogin },
                                  },
                                  [_vm._v(" Sign in with ArcGIS ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }