var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" Timezone? ")]
          ),
        ],
        1
      ),
      !_vm.timeZoneSubmitted
        ? _c("div", { staticClass: "d-flex justify-end" }, [
            _c(
              "form",
              [
                _c(
                  "v-list",
                  {
                    staticClass: "py-0",
                    style: {
                      width: "fit-content",
                      "border-radius": "1%",
                      border: "1px solid primary",
                    },
                    attrs: { dense: "" },
                  },
                  _vm._l(_vm.TIME_ZONE_CHOICES, function (c, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: c.label,
                        staticClass: "my-0 py-0",
                        style: {
                          "border-bottom":
                            i !== _vm.TIME_ZONE_CHOICES.length - 1
                              ? "1px solid primary"
                              : "",
                          "background-color": "white",
                        },
                        on: {
                          click: function ($event) {
                            _vm.user.timeZone = c.label
                            _vm.timeZoneSubmitted = true
                            _vm.saveTimeZone()
                          },
                        },
                      },
                      [
                        _c("span", { style: { color: "primary" } }, [
                          _vm._v(" " + _vm._s(c.label) + " "),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "d-flex justify-end my-5" },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    outlined: "",
                    rounded: "",
                    width: "fit-content",
                    dense: "",
                  },
                },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.user.timeZone) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }