<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        Email for {{ savedUser.firstName }}?
      </v-alert>
    </v-expand-transition>

    <div v-if="!emailSubmitted">
      <div>
        <validation-observer ref="emailForm">
          <form @submit.prevent="saveEmail">
            <validation-provider :rules="{ required: true, email: true }">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model="user.email"
                hide-details="auto"
                name="email"
                single-line
                background-color="white"
                full-width
                ref="emailInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="primary"
                      :disabled="!user.email"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ user.email }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "Step3UserFormEmail",
  props: {
    savedUser: Object,
  },
  data() {
    return {
      mdiSend,
      user: {
        email: "",
      },
      emailSubmitted: false,
    };
  },
  methods: {
    async saveEmail() {
      const success = await this.$refs.emailForm.validate();
      if (!success) {
        return;
      }
      await sleep(250);
      this.$emit("step-3-finished", this.user);
      this.emailSubmitted = true;
    },
  },
  async beforeMount() {
    await this.$nextTick();
    this.$refs.emailInput.$refs.input.focus();
  },
};
</script>
