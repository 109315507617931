var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-3",
      staticStyle: { width: "750px", "max-width": "750px" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between mb-1" },
        [
          _c(
            "div",
            { staticClass: "caption" },
            [
              _c("v-icon", { attrs: { size: "16px" } }, [
                _vm._v(" " + _vm._s(_vm.mdiAccountGroup) + " "),
              ]),
              _vm._v(" Departments "),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-0 mx-0",
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showAddDepartmentDialog = true
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                  ]),
                  _vm._v(" Department "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-card",
        { attrs: { width: "750px", "max-width": "750px" } },
        [
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-0" },
            [
              _vm.departments.length > 0
                ? _vm._l(_vm.departments, function (d) {
                    return _c(
                      "div",
                      {
                        key: d.department_id,
                        staticClass:
                          "d-flex justify-space-between align-center px-5 py-3",
                      },
                      [
                        _c(
                          "section",
                          { staticClass: "flex-grow-1 font-weight-medium" },
                          [_vm._v(" " + _vm._s(d.name) + " ")]
                        ),
                        _c(
                          "section",
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { icon: "" } },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.mdiDotsVertical
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass: "py-1",
                                        on: {
                                          click: function ($event) {
                                            _vm.selectedDepartmentId =
                                              d.department_id
                                            _vm.showEditDepartmentDialog = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action-text",
                                          {
                                            staticClass:
                                              "d-flex gap align-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { color: "primary" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiPencil) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "overflowText",
                                                staticStyle: {
                                                  color: "#286054",
                                                },
                                              },
                                              [_vm._v(" Edit ")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass: "py-1",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteDepartment(
                                              d.department_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action-text",
                                          {
                                            staticClass:
                                              "d-flex gap align-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { color: "primary" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiDelete) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "overflowText",
                                                staticStyle: {
                                                  color: "#286054",
                                                },
                                              },
                                              [_vm._v(" Delete ")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  })
                : _c("div", { staticClass: "px-5 py-3 text-center" }, [
                    _vm._v(" No departments have been added "),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.showAddDepartmentDialog
        ? _c("AddDepartmentDialog", {
            attrs: { showAddDepartmentDialog: _vm.showAddDepartmentDialog },
            on: {
              "add-departmeent-dialog-close": function ($event) {
                _vm.showAddDepartmentDialog = false
              },
              saved: function ($event) {
                _vm.showAddDepartmentDialog = false
                _vm.getDepartments()
              },
            },
          })
        : _vm._e(),
      _vm.showEditDepartmentDialog
        ? _c("EditDepartmentDialog", {
            attrs: {
              showEditDepartmentDialog: _vm.showEditDepartmentDialog,
              departmentId: _vm.selectedDepartmentId,
            },
            on: {
              "edit-departmeent-dialog-close": function ($event) {
                _vm.showEditDepartmentDialog = false
              },
              saved: function ($event) {
                _vm.showEditDepartmentDialog = false
                _vm.getDepartments()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }