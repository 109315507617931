<template>
  <v-dialog
    :value="showConfirmUpdateAllLayerDepartmentsDialog"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Layer Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-2">
        The layers on the selected maps must share the same department access.
        This department will be attached to all layers of the selected maps and
        detached from the maps not selected.
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('confirm')">
          SAVE DEPARTMENT AND UPDATE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmUpdateAllLayerDepartmentsDialog",
  props: {
    showConfirmUpdateAllLayerDepartmentsDialog: Boolean,
  },
};
</script>
