var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      _c("p", { staticClass: "caption" }, [_vm._v("Cityworks Account")]),
      _c(
        "v-card",
        [
          _c("v-card-text", [
            _c("div", [
              _vm._v(
                " Sign in using the account that will be responsible for creating Cityworks service requests. "
              ),
            ]),
            _vm.hasValidCityworksToken
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center gap" },
                  [
                    _c("div", { staticClass: "text-body-1" }, [
                      _vm._v("Signed In"),
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: { click: _vm.signOutOfCityworks },
                      },
                      [_vm._v(" Sign Out ")]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex align-center gap" },
                  [
                    _c("div", { staticClass: "text-body-1" }, [
                      _vm._v("Not Signed In"),
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.showCityworksSignInDialog = true
                          },
                        },
                      },
                      [_vm._v(" Sign In ")]
                    ),
                    _vm.showCityworksSignInDialog
                      ? _c("CityworksSignInDialog", {
                          attrs: {
                            cityworksIntegrationInfo:
                              _vm.cityworksIntegrationInfo,
                            showCityworksSignInDialog:
                              _vm.showCityworksSignInDialog,
                          },
                          on: {
                            "cityworks-sign-in-dialog-close": function (
                              $event
                            ) {
                              _vm.showCityworksSignInDialog = false
                            },
                            "cityworks-sign-in-dialog-succeed": function (
                              $event
                            ) {
                              _vm.showCityworksSignInDialog = false
                              _vm.updateCityworksToken()
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }