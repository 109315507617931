var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" What departments is this user a member of? ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex justify-end" }, [
        _c(
          "form",
          [
            _c(
              "v-list",
              {
                staticClass: "py-0 my-0",
                style: {
                  width: "fit-content",
                  "border-radius": "1%",
                  border: "1px solid primary",
                },
                attrs: { dense: "" },
              },
              _vm._l(_vm.departmentChoices, function (c) {
                return _c(
                  "v-list-item",
                  { key: c.value, staticClass: "d-flex align-center" },
                  [
                    _c("v-checkbox", {
                      staticClass: "py-0 my-0",
                      attrs: {
                        value: c.value,
                        dense: "",
                        "hide-details": "",
                        name: "selectedDepartmentIds",
                      },
                      model: {
                        value: _vm.selectedDepartmentIds,
                        callback: function ($$v) {
                          _vm.selectedDepartmentIds = $$v
                        },
                        expression: "selectedDepartmentIds",
                      },
                    }),
                    _c("section", [_vm._v(" " + _vm._s(c.label) + " ")]),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "section",
              { staticClass: "py-2" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.saveApp()
                      },
                    },
                  },
                  [_vm._v("Next")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }