var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Sorry, that username is already taken. Please enter another username. "
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.userNameSubmitted
        ? _c(
            "div",
            [
              _c("validation-observer", { ref: "userNameForm" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.saveUserName.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "validation-provider",
                      [
                        _c("v-text-field", {
                          ref: "usernameInput",
                          attrs: {
                            outlined: "",
                            color: "#E5E5E5",
                            "hide-details": "auto",
                            name: "username",
                            "single-line": "",
                            "background-color": "white",
                            "full-width": "",
                          },
                          on: { input: _vm.validateUsername },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mt-n2" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              type: "submit",
                                              color: "primary",
                                              disabled: !_vm.user.username,
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                " " + _vm._s(_vm.mdiSend) + " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1080958215
                          ),
                          model: {
                            value: _vm.user.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "username", $$v)
                            },
                            expression: "user.username",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    outlined: "",
                    rounded: "",
                    width: "fit-content",
                    dense: "",
                  },
                },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.user.username) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }