var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Organization" } }),
          _c(
            "div",
            { staticClass: "page-background d-flex justify-center px-1" },
            [
              _c(
                "v-card",
                {
                  staticClass: "elevation-0 my-16",
                  staticStyle: { "background-color": "#f1f2f1" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "elevation-0 pa-0" },
                    [
                      _c("LicensingPlan"),
                      _c("Integrations", {
                        attrs: { userGroup: _vm.userGroup },
                      }),
                      _c("Departments"),
                      _c("Users", {
                        attrs: { userGroup: _vm.userGroup },
                        on: {
                          "user-array": function ($event) {
                            _vm.users = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }