<template>
  <v-dialog v-model="showGisConnectionDialog" max-width="400px" persistent>
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>GIS Connection Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('gis-connection-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5">
        <p class="caption">GIS Connection Information</p>

        <v-card class="my-3">
          <v-card-text>
            <validation-observer>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Connection Type"
                rules="required"
              >
                <v-select
                  label="Connection Type"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="connectionType"
                  :items="CONNECTION_TYPE_CHOICES"
                  item-text="label"
                  item-value="value"
                  v-model="connectionType"
                  id="connectionTypeDropdown"
                >
                </v-select>
              </validation-provider>
            </validation-observer>
          </v-card-text>
        </v-card>

        <PortalConnection
          v-if="connectionType === CONNECTION_TYPES.PORTAL"
          @portal-updated="onPortalUpdated"
        />

        <div v-if="connectionSuccess" color="#093637" class="py-3">
          <template v-if="connectionType === CONNECTION_TYPES.PORTAL">
            Connected to ArcGIS Enterprise!
          </template>
          <template v-else> Connected to ArcGIS Online! </template>
        </div>
        <div v-else-if="connectionFailed" class="red--text py-3">
          {{ connectionErrorMsg || "Connection Failed" }}
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn
          :disabled="!connectionType || connectionSuccess"
          color="primary"
          @click="connect"
          id="connectButton"
        >
          <span
            :style="{
              color: !connectionType || connectionSuccess ? 'gray' : 'white',
            }"
          >
            Connect
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PortalConnection from "@/components/organization/integrations/shared/gis-connection-dialog/PortalConnection";
import axios from "axios";
import {
  CONNECTION_TYPES,
  ARC_GIS_CONNECTION_TYPES,
} from "@/constants/arcGisConnectionTypes";
import sleep from "@/mixins/sleep";

const APIURL = process.env.VUE_APP_API_URL;
const REDIRECT_URL = process.env.VUE_APP_REDIRECT_URL;
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
const CONNECTION_TYPE_CHOICES = [
  { label: "ArcGIS Online", value: CONNECTION_TYPES.ARCGIS_ONLINE },
  { label: "ArcGIS Enterprise", value: CONNECTION_TYPES.PORTAL },
];
const cancelTokenSource = axios.CancelToken.source();

const getPopUpDimensions = () => {
  const dualScreenLeft =
    typeof window.screenLeft !== "undefined" ? window.screenLeft : screen.left;
  const dualScreenTop =
    typeof window.screenTop !== "undefined" ? window.screenTop : screen.top;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;
  const popupWinWidth = 450;
  const popupWinHeight = 457;
  const left = width / 2 - popupWinWidth / 2 + dualScreenLeft;
  const top = height / 2 - popupWinHeight / 2 + dualScreenTop;
  return {
    popupWinWidth,
    popupWinHeight,
    left,
    top,
  };
};

export default {
  name: "GisConnectionDialog",
  props: {
    showGisConnectionDialog: Boolean,
    userGroupIntegrationSettings: Object,
  },
  components: {
    PortalConnection,
  },
  data() {
    return {
      CONNECTION_TYPE_CHOICES,
      CONNECTION_TYPES,
      connectionType: "",
      loginWindow: undefined,
      portalLoginWindow: undefined,
      portal: {
        portalUrl: "",
        appId: "",
      },
      connectionSuccess: false,
      connectionFailed: false,
      connectionErrorMsg: "",
    };
  },
  methods: {
    onPortalUpdated(portal) {
      this.portal = {
        ...this.portal,
        ...portal,
      };
    },
    connect() {
      this.connectionErrorMsg = "";
      this.connectionSuccess = false;
      this.connectionFailed = false;
      this.loginWindow = undefined;
      this.portalLoginWindow = undefined;
      if (this.connectionType === CONNECTION_TYPES.ARCGIS_ONLINE) {
        this.openArcGisSignIn();
      } else if (this.connectionType === CONNECTION_TYPES.PORTAL) {
        this.openPortalSignIn();
      }
    },
    openArcGisSignIn() {
      this.portalLoginWindow = undefined;
      const { popupWinWidth, popupWinHeight, left, top } = getPopUpDimensions();
      this.loginWindow = window.open(
        `https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${REDIRECT_URL}&expiration=20160`,
        "login",
        `menubar=0,width=${popupWinWidth},height=${popupWinHeight},left=${left},top=${top}`
      );
      this.loginWindow.focus();
    },
    openPortalSignIn() {
      const { appId, portalUrl } = this.portal;
      const portalSignInUrl = `${portalUrl}/sharing/oauth2/authorize?client_id=${appId}&response_type=token&redirect_uri=${REDIRECT_URL}&expiration=20160`;
      const { popupWinWidth, popupWinHeight, left, top } = getPopUpDimensions();
      this.portalLoginWindow = window.open(
        portalSignInUrl,
        "login",
        `menubar=0,width=${popupWinWidth},height=${popupWinHeight},top=${top},left=${left}`
      );
      this.portalLoginWindow.focus();
    },
    async onRedirectArcGisOnline(data) {
      const { access_token: accessToken, username } = data;
      try {
        const {
          data: {
            results: { gis_connection_type: gisConnectionType },
          },
        } = await axios.put(`${APIURL}/auth/set_GIS_connection`, {
          gis_connection_type: ARC_GIS_CONNECTION_TYPES.NO_GIS_CONNECTION,
        });
        if (
          gisConnectionType !== ARC_GIS_CONNECTION_TYPES.NO_GIS_CONNECTION ||
          !accessToken ||
          !username
        ) {
          return;
        }
        await axios.put(`${APIURL}/auth/set_GIS_connection`, {
          gis_connection_type: ARC_GIS_CONNECTION_TYPES.AGOL,
          username,
          esri_token: accessToken,
        });
        this.connectionSuccess = true;
      } catch (error) {
        this.connectionErrorMsg = error?.response?.data?.error;
        this.connectionFailed = true;
      }
    },
    async onRedirectPortal(data) {
      const { portalUrl, appId } = this.portal;
      const { access_token: accessToken, username } = data;
      try {
        const {
          data: {
            results: { gis_connection_type: gisConnectionType },
          },
        } = await axios.put(`${APIURL}/auth/set_GIS_connection`, {
          gis_connection_type: ARC_GIS_CONNECTION_TYPES.NO_GIS_CONNECTION,
          orgId: appId,
        });
        if (
          gisConnectionType !== ARC_GIS_CONNECTION_TYPES.NO_GIS_CONNECTION ||
          !accessToken ||
          !username ||
          !appId
        ) {
          return;
        }
        await axios.put(`${APIURL}/auth/set_GIS_connection`, {
          gis_connection_type: ARC_GIS_CONNECTION_TYPES.PORTAL,
          orgId: appId,
          username,
          esri_token: accessToken,
          esri_url: portalUrl,
          esri_client_id: appId,
        });
        this.connectionSuccess = true;
      } catch (error) {
        this.connectionErrorMsg = error?.response?.data?.error;
        this.connectionFailed = true;
      }
    },
    addMessageListener() {
      window.addEventListener("message", this.onMessage);
    },
    async onMessage({ data }) {
      this.loginWindow?.close?.();
      this.portalLoginWindow?.close?.();
      if (this.connectionType === CONNECTION_TYPES.ARCGIS_ONLINE) {
        await this.onRedirectArcGisOnline(data);
        await this.signIn(data, false);
      } else if (this.connectionType === CONNECTION_TYPES.PORTAL) {
        await this.onRedirectPortal(data);
        await this.signIn(data, true);
      }
      if (this.connectionSuccess) {
        await sleep(2000);
        this.$emit("connection-success");
      }
    },
    async signIn(data, isPortal) {
      const { access_token: accessToken } = data;
      if (!accessToken) {
        return;
      }

      localStorage.setItem("esri_token", accessToken);
      try {
        const resData = await axios.post(`${APIURL}/auth/esri_login`, {
          access_token: accessToken,
          portal_url: isPortal
            ? this.portal.portalUrl
            : "https://www.arcgis.com",
        });
        const {
          data: {
            results: { refreshToken, esriUsername },
          },
        } = resData;
        localStorage.setItem("refresh_token", refreshToken);
        localStorage.setItem("esri_user", esriUsername);
        const refreshTokenData = await axios.post(
          `${APIURL}/auth/refresh_token`,
          {
            refresh_token: localStorage.getItem("refresh_token"),
          }
        );
        const {
          data: {
            results: { token },
          },
        } = refreshTokenData;
        localStorage.setItem(
          "auth",
          JSON.stringify(refreshTokenData.data.results)
        );
        cancelTokenSource.cancel();
        localStorage.setItem("token", token);

        const newAuth = {
          ...refreshTokenData.data.results,
        };
        localStorage.setItem("auth", JSON.stringify(newAuth));
      } catch (error) {
        throw new Error(error);
      }
    },
    setConnectionType() {
      if (
        this.userGroupIntegrationSettings?.gis_connection_type ===
        ARC_GIS_CONNECTION_TYPES.AGOL
      ) {
        this.connectionType = CONNECTION_TYPES.ARCGIS_ONLINE;
      } else if (
        this.userGroupIntegrationSettings?.gis_connection_type ===
        ARC_GIS_CONNECTION_TYPES.PORTAL
      ) {
        this.connectionType = CONNECTION_TYPES.PORTAL;
      }
    },
  },
  beforeMount() {
    this.addMessageListener();
    this.setConnectionType();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  watch: {
    userGroupIntegrationSettings: {
      deep: true,
      handler() {
        this.setConnectionType();
      },
    },
  },
};
</script>
