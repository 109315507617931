export const CONNECTION_TYPES = {
  ARCGIS_ONLINE: 1,
  PORTAL: 2,
};

export const ARC_GIS_CONNECTION_TYPES = {
  NO_GIS_CONNECTION: "no_gis_connection",
  AGOL: "agol",
  PORTAL: "portal",
};
